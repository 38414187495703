import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
   
// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

    


import Accueil from "./Pages/Accueil"

import MaconUser from "./Pages/MaconUser";

 // Mise en service 
// GARBAL
import ListMiseService from "./Pages/MiseService/ListMiseService";
import EditMiseService from "./Pages/MiseService/EditMiseService";

// SAV  
import ListSAV from "./Pages/SAV/ListSAV";
import EditSAV from "./Pages/SAV/EditSAV";

// Sivi Hors Site
import ListSuiviHorsSite from "./Pages/SuiviHorsSite/ListSuiviHorsSite";
import EditSuiviHorsSite from "./Pages/SuiviHorsSite/EditSuiviHorsSite";

// Suivi Classic  
import ListSuiciClassic from "./Pages/SuiviClassic/ListSuiciClassic";
import EditSuivi_Classic from "./Pages/SuiviClassic/EditSuivi_Classic";

// Recepton provisoire  
import ListReceptProv from "./Pages/ReceptProv/ListReceptProv";
import EditReceptprov from "./Pages/ReceptProv/EditReceptprov"

// Gestion Ouvrages  
import ListNewOuvrage from "./Pages/NewOuvrage/ListNewOuvrage";
import ListActiveOuvrage from "./Pages/ActivationOuvrage/ListActiveOuvrage";
import EditNewOuvrage from "./Pages/NewOuvrage/EditNewOuvrage"

// Creation Ouvrages  
// import ListNewOuvrage from "./Pages/NewOuvrage/ListNewOuvrage";
import EditCreation from "./Pages/NewOuvrage/EditCreation"
 

// GARBAL
import ListGARBAL from "./Pages/GARBAL/ListGARBAL";
import EditGARBAL from "./Pages/GARBAL/EditGARBAL";

// User Params
import ListUserParams from "./Pages/UserParams/ListUserParams";
import EditUserParams from "./Pages/UserParams/EditUserParams";

  const Lien =  process.env.PUBLIC_URL 

export default [
  // {
  //   path: "/",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: () => <Redirect to="/blog-overview" />
  // },


  // <img src = {process.env.PUBLIC_URL + '/Accueil'} />

  {
    path:  Lien + '/Accueil' ,
    layout: DefaultLayout,
    component: Accueil
  },
  {
    path: Lien + "/Mise_en_service",
    layout: DefaultLayout,
    component: ListMiseService   
  },
  {
    path: Lien + "/Edit_Mise_en_service",
    layout: DefaultLayout,
    component: EditMiseService
  },
  {  
    path: Lien +  "/SAV",
    layout: DefaultLayout,
     component: ListSAV
  },
  {
    path: Lien + "/Edit_SAV",
    layout: DefaultLayout, 
    component: EditSAV
  },
  {  
    path: Lien + "/List_Suivi_Hors_Site",
    layout: DefaultLayout,
     component: ListSuiviHorsSite
  },
  {
    path: Lien + "/Edit_Suivi_Hors_Site",
    layout: DefaultLayout, 
    component: EditSuiviHorsSite
  },


  {  
    path: Lien + "/Suivi_Classic",
    layout: DefaultLayout,
     component: ListSuiciClassic
  },
  {
    path: Lien + "/Edit_Suivi_Classic",
    layout: DefaultLayout, 
    component: EditSuivi_Classic
  },
  {  
    path: Lien + "/GARBAL",
    layout: DefaultLayout,
    component: ListGARBAL
  },
  {
    path: Lien + "/Edit_GARBAL",
    layout: DefaultLayout,
    component: EditGARBAL
  },

  {
    path: Lien + "/Suivi_classique",    
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Recep_provisoire",
    layout: DefaultLayout,
    component: ListReceptProv
  },
  {
    path: Lien + "/Edit_ReceptProv",
    layout: DefaultLayout,
    component: EditReceptprov
  },
  {
    path: "/Suivi_hors_site",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Parametrese",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Connexion",       
    layout: DefaultLayout,
    component: MaconUser
  },
  {
    path: Lien + "/UserParams",       
    layout: DefaultLayout,
    component: ListUserParams
  },
  {
    path: Lien + "/EditUserParams",       
    layout: DefaultLayout,
    component: EditUserParams
  },
  {
    path: Lien + "/ListeOuvrages",       
    layout: DefaultLayout,
    component: ListNewOuvrage
  },
  {
    path: Lien + "/ListActiveOuvrage",       
    layout: DefaultLayout,
    component: ListActiveOuvrage
  },
  {
    path: Lien + "/EditNewOuvrage",       
    layout: DefaultLayout,
    component: EditNewOuvrage
  },
  {
    path: Lien + "/EditCreation",       
    layout: DefaultLayout,
    component: EditCreation
  },
  {
    path: Lien + "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: Lien + "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: Lien + "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: Lien + "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },


];


/**
 
  
if ( TypeConnexion === "Administrateur") {

  alert( "okokok")
  return [

    
  {
    path:  Lien + '/Accueil' ,
    layout: DefaultLayout,
    component: Accueil
  },
  {
    path: Lien + "/Mise_en_service",
    layout: DefaultLayout,
    component: ListMiseService   
  },
  {
    path: Lien + "/Edit_Mise_en_service",
    layout: DefaultLayout,
    component: EditMiseService
  },
  {  
    path: Lien +  "/SAV",
    layout: DefaultLayout,
     component: ListSAV
  },
  {
    path: Lien + "/Edit_SAV",
    layout: DefaultLayout, 
    component: EditSAV
  },
  {  
    path: Lien + "/List_Suivi_Hors_Site",
    layout: DefaultLayout,
     component: ListSuiviHorsSite
  },
  {
    path: Lien + "/Edit_Suivi_Hors_Site",
    layout: DefaultLayout, 
    component: EditSuiviHorsSite
  },


  {  
    path: Lien + "/Suivi_Classic",
    layout: DefaultLayout,
     component: ListSuiciClassic
  },
  {
    path: Lien + "/Edit_Suivi_Classic",
    layout: DefaultLayout, 
    component: EditSuivi_Classic
  },
  {  
    path: Lien + "/GARBAL",
    layout: DefaultLayout,
    component: ListGARBAL
  },
  {
    path: Lien + "/Edit_GARBAL",
    layout: DefaultLayout,
    component: EditGARBAL
  },

  {
    path: Lien + "/Suivi_classique",    
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Recep_provisoire",
    layout: DefaultLayout,
    component: ListReceptProv
  },
  {
    path: Lien + "/Edit_ReceptProv",
    layout: DefaultLayout,
    component: EditReceptprov
  },
  {
    path: "/Suivi_hors_site",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Parametrese",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/Connexion",       
    layout: DefaultLayout,
    component: MaconUser
  },
  {
    path: Lien + "/UserParams",       
    layout: DefaultLayout,
    component: ListUserParams
  },
  {
    path: Lien + "/EditUserParams",       
    layout: DefaultLayout,
    component: EditUserParams
  },
  {
    path: Lien + "/ListeOuvrages",       
    layout: DefaultLayout,
    component: ListNewOuvrage
  },
  {
    path: Lien + "/ListActiveOuvrage",       
    layout: DefaultLayout,
    component: ListActiveOuvrage
  },
  {
    path: Lien + "/EditNewOuvrage",       
    layout: DefaultLayout,
    component: EditNewOuvrage
  },
  {
    path: Lien + "/EditCreation",       
    layout: DefaultLayout,
    component: EditCreation
  }
    
  

  ,
  {
    path: Lien + "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: Lien + "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: Lien + "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: Lien + "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: Lien + "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },

  ];



}  else if ( TypeConnexion === "Utilisateur") {

    return [

      {
        path:  Lien + '/Accueil' ,
        layout: DefaultLayout,
        component: Accueil
      },

      {
        path: Lien + "/ListeOuvrages",       
        layout: DefaultLayout,
        component: ListNewOuvrage
      }
  
    ];

} else if ( TypeConnexion === "Entreprise") {

  return [

    {
      path:  Lien + '/Accueil' ,
      layout: DefaultLayout,
      component: Accueil
    },

    {
      path: Lien + "/EditCreation",       
      layout: DefaultLayout,
      component: ListNewOuvrage
    }




  ];

}
else{

  return []
}



 */