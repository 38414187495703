




import React, { useState, useEffect } from 'react';
//import PropTypes from "prop-types";
//import useForm from 'react-hook-form';
import axios from 'axios';
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  Alert,
  ListGroupItem,
  Container, Row, Col,
  Form,FormInput,FormGroup,InputGroup,InputGroupAddon,InputGroupText,FormSelect
} from "shards-react";
import PageTitle from "../../src/components/common/PageTitle";
import { Redirect  } from "react-router-dom";
//import { Redirect } from 'react-router'
//import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 

 
import Params from "../../src/Pages/Config/Params"; ; 
// import Logos from "../assets/logo.jpg"; ; 

// import ReactSession from 'react-client-session';

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
   
 let Pays = Params.Pays
const  Maconnexion =  (props) => {
	const [Email, setEmail] = useState('')
	const [Password, setPassword] = useState('')
//	const [MonToken, setMonToken] = useState('')
	const [success, setsuccess] = useState('')
	const [redirection, setredirection] = useState('')
	const [TypeConnexion, setTypeConnexion] = useState('')


const [Region,setRegion] = useState( [])
const [Province,setProvince] = useState( [])
const [Commune,setCommune] = useState( [])
const [Village,setVillage] = useState( [])
const [MotifAppel,setMotifAppel] = useState( [])


	Store2.session("token","" )
	Store2.session("MaRedirection", false )
	const Token = Store2.session("token" ) 


	useEffect(() => {

	//	Recupeartion()

      }, []);
	
	  
	





	const	Connexion  =  () => {

	
	if ( TypeConnexion === "Choisir le type de connexion ..." || TypeConnexion === "" ) {
		alert(" Selectionner le type de connexion SVP")
		return ;
		// this.props.history.push("/Accueil")
		
	}

		const Login = {
			email: Email,
			password : Password,
			TypeCon: TypeConnexion
			
		}; 
/**
 * 
 
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		//	Authorization: 'Bearer ' + token,
		}

 */
		// Params.Api + `/userlogin'
	// 	alert( Params.Api + ' -  '  +  Login )
	console.log( Params.Api  )

	axios.post(Params.Api + '/userlogin', Login )
	  .then(function (response) {

		const success = response.data.success
 // alert ( 'response = ' + JSON.stringify( response ))
// if(typeof(response.data.token) !== 'undefined'  ||  response.data.token  !== 'undefined'  ) {
if (success){

			//	alert( JSON.stringify( response.data.currUser ));

				// global.token = JSON.stringify(response.data.token);
				//global.token = response.data.token;//
				//global.MaRedirection = true
				
				Store2.session("token", response.data.token )
				Store2.session("UserId", response.data.currUser )
				//alert( Store2.session("token" )  )

				Store2.session("MaRedirection", true )
				Store2.session("TypeConnexion", TypeConnexion )
				Store2.session("EmailUser", response.data.email )

				
				//alert( JSON.stringify(response.data.token));
			//	alert( global.token  );
				
			///window.location.reload(false);
			   props.history.push("/Accueil")
			   window.location.reload(false);
			 // return <Redirect to='/Accueil'/>;

			 

		}else{
			alert(' Verifier votre email/mot de passe et/ou le type de connexion. ')
		}
		

	  })
	  .catch(function (error) {
		if (error.response) {

			console.log(error.response.data);
			console.log(error.response.status);
			console.log(error.response.headers);


		}  else if (error.request){

			console.log(error.request);

		}else {
			// Something happened in setting up the request that triggered an Error
		//	console.log('Error', error.message);
		  }

		alert(`Erreur du serveur : constacter l'administrateur \n ` + error );
		return <Redirect to='/'/>;
	  });


	}




	const PageRedirection = (e) => {
        e.preventDefault();
        this.props.history.push("/Accueil")
   // <Redirect to="/Accueil" /> 
}
		  

 /** 
  alert(global.MaRedirection)
  if (global.MaRedirection) {
   //Affichage de la redirection
   alert('Passe ok')
   return <Redirect to='/Accueil'/>;
}
*/ 


// alert( 'Token = ' +  Token)

if (Token  === undefined || Token  === "" ) {
	//Affichage de la redirection
	//alert('vous etes deja connecter')
	//return <Redirect to='/'/>;

  }else{

	return <Redirect to='/Accueil'/>;
  }

  

return (

<Container fluid className="main-content-container px-4">


    <Row noGutters className="page-header py-4">
      <PageTitle title="Connexion" subtitle="BioSynchro plateforme" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>


    <Row>
      <Col lg="4">
        
      </Col>
      <Col lg="4">
        
				<Card small className="mb-4 pt-3">
			<CardHeader className="border-bottom text-center">
			  <div className="mb-3 mx-auto">
				<img
				   // className="rounded-circle"
				  //src={userDetails.avatar}
				  src={require("../assets/Petit/" + Pays + ".png")}
				  //alt={userDetails.name}
				  width="250"
				/>
			  </div>
			  <h4 className="mb-0">{userDetails.name}</h4>
			 {/* <Button pill outline size="sm" className="mb-2">
				<i className="material-icons mr-1">A propos</i> de ROFRHB
			  </Button>
			 */} 
			</CardHeader>
			<ListGroup flush>
			  <ListGroupItem className="px-4">
				<div className="progress-wrapper">
				 
				  
					<Form // onSubmit={this.Connexion()} 
					>
                    
					  <FormGroup>
						<InputGroup className="mb-3">
						  <InputGroupAddon type="prepend">
							<InputGroupText>@</InputGroupText>
						  </InputGroupAddon>
						  <FormInput 
						  id="email"
                          type="email"
                          name="email"
                          value={Email} 
                          onChange={(e) => {
								setEmail(e.target.value)
							}}
						  placeholder="Entrer votre email" 		 
						  />
						  
						</InputGroup>
					  </FormGroup>
					  <FormGroup>
						<FormInput
						   id="password"
						  type="password" 
						   name="password"
						   value={Password} 
						   onChange={(e) => {
								setPassword(e.target.value)
							}}
						  placeholder="Mot de passe" 					  
						/>
						
					  </FormGroup>

					  <FormGroup>

					  <FormSelect id="TypeConnexion"
					  /* required */
					  value={TypeConnexion} 	
						onChange={(e) => {
							setTypeConnexion(e.target.value)
						}}
					  >
							<option>Choisir le type de connexion ...</option>
							<option> Administrateur </option>
							<option> Utilisateur </option>
							<option> Entreprise </option>
						  </FormSelect>

					 </FormGroup>
				{/**  <Button theme="accent" onClick={this.Connexion()} >Connexion</Button>   */}
					 <Button onClick={()=> Connexion() }>
						 Connexion
                     </Button>

                      
					</Form> 

	  
				</div>
			  </ListGroupItem>
			  <ListGroupItem className="p-4">
				<strong className="text-muted d-block mb-2">
				  {userDetails.metaTitle}
				</strong>
				<span>{userDetails.metaValue}</span>
			  </ListGroupItem>
			</ListGroup>
		  </Card>
		
		
      </Col>
	  <Col lg="4">
        
      </Col>
    </Row>



 {/**
  
  
	<MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
  <TileLayer
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[51.505, -0.09]}>
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer>


  */}

  </Container>
  
 );
}


 const  userDetails= {
    name: "Connexion",
    avatar: require("./../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Consigne de connexion",
    metaValue:
      "Vous étes sur le point de vous connecter à une plateforme de données sur les biodigesteurs du " + Pays + ".En cas de difficultés, veuillez contacter l'administrateur système."
  }


export default Maconnexion;
