import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, DatePicker, InputGroupText,
    CardHeader, CardBody, FormSelect,label, InputGroupAddon,
    ButtonToolbar,ButtonGroup,InputGroup,Modal, ModalBody, ModalHeader,
    Button,FormInput } from "shards-react";

// import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";


import { MDBDataTable,
        MDBDataTableV5 ,
       } from 'mdbreact';

// import { MDBDatePickerV5 } from 'mdb-ui-kit';
 import moment from 'moment';

 import EditMiseService from './F_MiseService_Modal';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import PageTitle from "../../components/common/PageTitle"; 
import axios from 'axios';
//import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params";  
import { Redirect  } from "react-router-dom";

import { Dialog } from 'uxcore';
import { FaSistrix, FaSyncAlt } from "react-icons/fa";
import { Audio, BallTriangle } from  'react-loader-spinner'

const  ListMiseService  = (props) => {

  // let history = useHistory();
  // const  Entete =  global.token  
  const ligneMiseService  =   Store2.session("ligneMiseService")
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 
  const RegionSelect = Store2.session("RegionVal" )


 // alert( Store2.session("RegionVal" )   )

  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];


const [Open,setOpen] = useState( false )

/////////// FIN EXCEL EXPORT DATA

  const [Content, setContent] = useState()
  // const [datatable, setDatatable] = useState({columns:[], rows:[] })
  //Store2.session("ligne", []); 
  const [datatable, setDatatable] = useState()
  const [MaSelection, setMaSelection] = useState()
  const [Region, setRegion] = useState('')
  const [DateDebut, setDateDebut] = useState()
  const [DateFin, setDateFin] = useState()
  const [Motif, setMotif] = useState('')
  const [MotifAppel,setMotifAppel] = useState('')

  const [Visible,setVisible] = useState(false)
  const [TestDonnes,setTestDonnes] = useState(0)
  //const LigneClick = "GANSBEOGO"

  const [Chargement, setChargement] = useState(false);
  const [MonFetch, setMonFetch] = useState([]);
  const [Monerror, setMonerror] = useState(null);
  
  


useEffect(() => {
  // loadData();
  // MesDonnees( Store2.session("ligneSAV") )
  fetchData()

}, []);




const fetchData = async () => {
  setChargement(true);
  setMonerror(null);

  try {
    const response = await axios.get( Params.Api +  '/api/dsh_miseService/?token=' + Token  + '&region=' + RegionSelect   );
    setMonFetch(response.data);
    MesDonnees(response.data )  
    // alert( response.data.length)
  } catch (error) {
    setMonerror(error.message);
  }

  setChargement(false);
};




// http://192.168.223.1:4200/api/region/?token=
//	axios.get( Params.Api + '/api/region/?token=' + Token )
  

const loadData = async () => {
  const Token = Store2.session("token" )
  const response = await fetch( Params.Api + '/api/dsh_miseService/?token=' + Token);
  //  const contentData = await response.json();
  const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneMiseService", contentData); 


  // // if(isNaN( Content.length) == true)
  
  // if (typeof(Content) != "undefined"){
  //    alert( "Recup  ok= " +  Content.length)
  //   setTestDonnes( 1 )

    
  // }else{
  //   setTestDonnes( 0 )
  //   alert( "Recup  NONok " )
  // }

  // MesDonnees(contentData)
}



const MesDonnees =  (data) => {
  setDatatable()
  setDatatable({
    columns: [   
      {
        label: 'Id',
        field: 'idmiseenservice',
        width: 10,
        sort: 'asc',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'id',
        },
      },
      {
        label: 'Plan_code_ouvrage',
        field: 'plancode',
        width: 100,
      },
      {
        label: 'Type_ouvrage',
        field: 'typeouvrage',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Nom_Client',
        field: 'Nomprenomclient',
      // sort: 'asc',
        width: 150,
      },
      {
        label: 'Nom_Ecb',
        field: 'nomecb',
      // sort: 'asc',
        width: 100,
      },
      {
        label: 'Region',
        field: 'region',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Province',
        field: 'province',
      // sort: 'asc',
        width: 50,
      },
      {
        label: 'Commune',
        field: 'commune',
      // sort: 'asc',         
        width: 50,
      },
      {
        label: 'Village',
        field: 'village',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Etat',
        field: 'etatouvrage',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Obs_etat',
        field: 'observation_etat',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Form_client',
        field: 'formationclient',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Thematique_form',
        field: 'thematiqueformation',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Date_prochain_rdv',
        field: 'daterdv',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Date_suivi',
        field: 'rdv',
        //sort: 'asc',
        width: 50,
      },
      {
        label: 'Date_mise_service',
        field: 'datemieenservice',
        //sort: 'asc',
        width: 50,
      },
  
      // {
      //   label: 'Observation',
      //   field: 'observation',
      //   //sort: 'asc',
      //   width: 50,
      // },
      {
        label: 'Date_MAJ',
        field: 'datemiseajour',
        //sort: 'asc',
        width: 50,
      },
  
    ],
    rows: data,
    
  })
}




const RechercheDate = async () => {
  setChargement(true);
  setMonerror(null);

  try {
    const response = await axios.get( Params.Api +  '/api/dsh_miseService/?token=' + Token  + '&region=' + RegionSelect  );
   
    const jsonData = await response.data;

    // const startDate =  '2023-03-01'
    // const endDate = '2023-03-10'

      const startDate = new Date ( moment(DateDebut).format("YYYY-MM-DD") ) ;
      const endDate =   new Date (moment(DateFin).format("YYYY-MM-DD"));

   // Exclure les dates null
   // const nonNullDates = jsonData.filter(item => item.datereceptprov !== null);

   const nonNullDates = jsonData.filter(item => {
    const date = new Date(item.datereceptprov);
    // return item.datereceptprov !== null && date >= new Date('2023-03-1') && date <= new Date('2023-03-11');
    return item.datesuivi !== null && date >= startDate && date <= endDate ;
  });

  
    setMonFetch( nonNullDates);
    MesDonnees( nonNullDates ) 


  } catch (error) {
    setMonerror(error.message);
    alert( error)
  }

  setChargement(false);
};



const Raffraichir = async () => {
  setChargement(true);
  setMonerror(null);

  try {
    const response = await axios.get( Params.Api +  '/api/dsh_miseService/?token=' + Token + '&region=' + RegionSelect );
    setMonFetch(response.data);
    MesDonnees(response.data )  
    // alert( response.data.length)
  } catch (error) {
    setMonerror(error.message);
  }

  setChargement(false);
};




const show = () => {
  setVisible(true)
  }





const Supprimer = async () => {

  if ( TypeConnexion === "Partenaire" ||  TypeConnexion === "Utilisateur" ){
	  alert(" Vous n'avez pas cette autorisation. Contacter l'administrateur.")
	  return
  }



  const Token = Store2.session("token" )

//  Test de confirmation de selection
  if( MaSelection ){
        
          let Reponnse = window.confirm("Êtes-vous sûr de vouloir supprimer cette ligne ?");
// Test de confirmation de suppression
          if (Reponnse ) {
        
                    try {
                      const contentData = await axios.put( Params.Api +  `/api/dsh_miseService_sup/?token=` + Token + `&id=` + checkbox1.idmiseenservice )
                    
                      const Res = JSON.stringify(contentData.data.Error)

                  //  alert ( Res  )
                    
                      if (Res  === true ){
                        alert("Suppression impossible conctacter Admin")
                        
                      
                      }else{

                        alert("Suppession terminée avec succès")
                      }
                  
                      Raffraichir()
        
                    
                    } catch (error) {
                      alert("Verifier votre connexion SVP.");
                    }
                    
          } else {
            // Do nothing!
        
          }
  
    



    
  }else{
    alert("Selctionner un élément dans la liste SVP")
  }



  



}



const Modifier = () => {
//	e.preventDefault();
//const  history = useHistory();
// alert( 'ID = ' + MaSelection)

if( MaSelection ){
  props.history.push("/Edit_Mise_en_service")
}else{
  alert("Selctionner un élément dans la liste")
}

}


const Nouveau = () => {
  //	e.preventDefault();
  //const  history = useHistory();
  // alert( 'ID = ' + MaSelection)

  Store2.session("ligneSelectMiseService", "")
  props.history.push("/Edit_Mise_en_service")


}

const [checkbox1, setCheckbox1] = useState('');
const showLogs2 = (e) => {
  setCheckbox1(e);
  setMaSelection(e.idmiseenservice)
  Store2.session("ligneSelectMiseService", e)
};

const Lignes =  Store2.session("ligneMiseService")

//alert (JSON.stringify(Lignes))


const ChangeDateDebut = (value) => {
setDateDebut( new Date(value) )
}

const ChangeDateFin  = (value) => {
  setDateFin( new Date(value) )
}

 
                      
const ExportationExcel = () => {

//  const Donnees =  Store2.session("ligneMiseService"); 
// alert("ooo")
 const Donnees =  MonFetch


  return (
    <ExcelFile element={
    <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
    Exporter
    </Button>}>
                <ExcelSheet filename ="ExportMiseEnService" data={Donnees} name="Employees">
                    <ExcelColumn label="plancode" value="plancode"/>
                    <ExcelColumn label="typeouvrage" value="typeouvrage"/>
                    <ExcelColumn label="NomPrenomClient" value="Nomprenomclient"/>
                    <ExcelColumn label="NomPrenomMacon" value="Nomprenommacon"/>
                    <ExcelColumn label="NomEcb" value="NomEcb"/>

                    <ExcelColumn label="etatouvrage" value="etatouvrage"/>
                    <ExcelColumn label="thematiqueformation" value="thematiqueformation"/>
                    <ExcelColumn label="formationclient" value="formationclient"/>
                    <ExcelColumn label="Region" value="region"/>
                    <ExcelColumn label="Province" value="province"/>
                    <ExcelColumn label="Commune" value="commune"/>
                    <ExcelColumn label="Village" value="village"/>
                    <ExcelColumn label="observation" value="observation"/>
                    <ExcelColumn label="daterdv" value="daterdv"/>
                    <ExcelColumn label="rdv" value="rdv"/>
                    <ExcelColumn label="datemieenservice" value="datemieenservice"/>


{/** 
 
 
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>

*/}
                </ExcelSheet>
{/** 
 
                 <ExcelSheet  filename ="ExportGarbal" data={dataSet2} name="Leaves">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Total Leaves" value="total"/>
                    <ExcelColumn label="Remaining Leaves" value="remaining"/>
                </ExcelSheet>

*/}
            </ExcelFile>
);





}


const MesBoutons = () => {

  if ( TypeConnexion === "Administrateur"){

    return(

      <>   

       <ExportationExcel />

      <Button onClick={() => Nouveau()} marginRight={8} 
      outline small theme="dark" className="mb-2 mr-1" >
      Nouveau
      </Button>


      
      
      <Button onClick={() => Modifier()}
      marginRight={8}  outline theme="success" className="mb-2 mr-1">
        Modifier
      </Button>

      <Button onClick={() => Supprimer()} marginRight={8} 
      outline theme="danger" className="mb-2 mr-1" >
      Supprimer
      </Button>

      </>   

    )

  } else{

    return null
  }

}



const ExportationExcelParams = () => {

  const Donnees =  Store2.session("ligneMiseService"); 
 
 /**
  
  if modal non activer
  return
 else
 
  */
 
   return (
     <ExcelFile element={
     <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
     Exporter
     </Button>}>
                 <ExcelSheet filename ="ExportGarbal" data={Donnees} name="Employees">
                     <ExcelColumn label="date_appel" value="date_appel"/>
                     <ExcelColumn label="nom_appelant" value="nom_appelant"/>
                     <ExcelColumn label="contact_appelant" value="contact_appelant"/>
                     <ExcelColumn label="profil_appelant" value="profil_appelant"/>
                     <ExcelColumn label="region" value="region"/>
                     <ExcelColumn label="province" value="province"/>
                     <ExcelColumn label="commune" value="commune"/>
                     <ExcelColumn label="village" value="village"/>
                     <ExcelColumn label="observation" value="observation"/>

                     <ExcelColumn label="typeanimaux" value="typeanimaux"/>
                     <ExcelColumn label="nombretete" value="nombretete"/>
                     <ExcelColumn label="quantitebouse" value="quantitebouse"/>
                     <ExcelColumn label="fonctionnement" value="etat"/>
                     <ExcelColumn label="typeaccessoires" value="typeaccessoires"/>
                     <ExcelColumn label="quantite" value="quantite"/>
                     <ExcelColumn label="unite" value="unite"/>
                     <ExcelColumn label="traite" value="traite"/>


                 </ExcelSheet>

             </ExcelFile>
 );
 
 }



const ExportationExcel2 = () => {

  
const multiDataSet = [
  {
    columns: [
      { value: "Name", widthPx: 50 }, // width in pixels
      { value: "Salary", widthCh: 20 }, // width in charachters
      { value: "Sex", widthPx: 60, widthCh: 20 }, // will check for width in pixels first
    ],
    data: [
      ["Johnson", 30000, "Male"],
      ["Monika", 355000, "Female"],
      ["Konstantina", 20000, "Female"],
      ["John", 250000, "Male"],
      ["Josef", 450500, "Male"],
    ],
  },
  {
    xSteps: 1, // Will start putting cell with 1 empty cell on left most
    ySteps: 5, //will put space of 5 rows,
    columns: ["Name", "Department"],
    data: [
      ["Johnson", "Finance"],
      ["Monika", "IT"],
      ["Konstantina", "IT Billing"],
      ["John", "HR"],
      ["Josef", "Testing"],
    ],
  },
];


  return (
    <ExcelFile element={
      <Button  marginRight={8}  outline theme="dark" className="mb-2 mr-1" >
      Exporter2
      </Button>}>
      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
    </ExcelFile>
);

}

const ExportModal = () => {

	if(Open){
		setOpen(false)
	}else{
		setOpen(true)
	}

	///setTraite(Checked)


  }

const ExportChoix = () => {
alert (MotifAppel)
    let Reponnse = window.confirm("Exporter avec votre choix de motif ?");
    // Test de confirmation de suppression
    if (Reponnse) {
       return( < ExportationExcelParams /> )

    }else{
      return(  < ExportationExcel /> )
    }

  }

const MaRegion =   Store2.session("Region")
// const Donnees= Store2.session("ligneMiseService")
const MotifGarbal =   Store2.session("MotifGarbal")
// alert ( JSON.stringify( Donnees))





// if ( datatable  ){
// if( TestDonnes === 1 ){
    
// alert( typeof(datatable) )
  if (typeof(datatable) != "undefined"){

  
if ( Token  === undefined  ||  Token  === ""  || TypeConnexion === "Opérateur Garbal" ) {
  //Affichage de la redirection
 // alert('  Erreur : Verifier votre Email / Mot de passe. ')
  return <Redirect to='/'/>;

}

// alert ( datatable.length )

  const today = new Date();
  const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
  const twoDaysAgo = (d => new Date(d.setDate(d.getDate() - 2)))(new Date());



  const ModalContent = () => {
    return(
   
        <Modal  size="md" centered = {true}  backdrop = {false} open={Open} toggle={ExportModal}>
           <ModalHeader>Exportatation par motif</ModalHeader>
            < ExportationExcel/>

            <Row >
            {/* MotifAppelant */}
              <Col md="6" className="form-group">
                <label htmlFor="MotifAppelant">Motif de l'appelant </label>
                <FormSelect id="MotifAppel"
                  /* required */
                  value={Motif}
                  onChange={(e) => {
                    e.preventDefault();
                    setMotif(e.target.value)
                    // alert( "valuer = " + e.target.value)
                  }}
                >
                  <option> </option>
                  {
                    MotifGarbal.map(item => {
                      return (
                        <option value={item.id}>{item.motif} </option>
                      )
                    })
                  }
                </FormSelect>

              </Col>
              {/* DateDebut */}
              <Col md="6" className="form-group">
              <InputGroup size="sm" className="ml-auto">

{ /** VOI apres comment gerer les deux InputGroup  */}

       <InputGroup size="sm" className = "d-flex my-auto date-range">




    <InputGroupAddon type="append">

            
          <InputGroupText> 
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>

        </InputGroupAddon>


        <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
        <DatePicker
          size="sm"
          selected={DateFin}
          onChange={ChangeDateFin}
          placeholderText="Date Fin"
          dropdownMode="select"
          className="text-center"
        />

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>
					  <FormSelect id="Regions"
					  /* required */
					  value={Region} 
						onChange={(e) => {
              setRegion(e.target.value)
           }}
					  >

              {
                MaRegion.map( item => {
                  return(
                 <option>{item.nom} </option>
                  )
                })
              }
						
					  </FormSelect>


    </InputGroup>


             </InputGroup>

              </Col>
    
            </Row>

          </Modal>

   
    )
  }



  return (
    <>
       <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Mise en service" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>

            
            <Card small className="mb-4">
            <CardHeader className="border-bottom">
            <CardBody className="p-0 pb-3">


            <Row>   

                                <Col  md="12">

         <ButtonToolbar>

                    <ButtonGroup size="sm">

                  
                    
 {
   /** 
    
                      <Button onClick={() => 
                    ExportModal()
                  // ExportChoix()
                     } marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Export
                      </Button>
                      < ModalContent />
                      
   */
 }
                      
  


      
                      <MesBoutons />
      

                   

                      <Button onClick={() => Raffraichir()} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >
                        {/* <i className="material-icons-outlined">&#xE895;</i> */}
                        <FaSyncAlt style={{color: 'blue' , fontSize: '20px' }}/>  
                      </Button>


                      <Button onClick={() => {
                        // loadDataParam() 
                        RechercheDate()
                      } } marginRight={8} 
                                        
                      outline theme="dark" className="mb-2 mr-1" >
                         <FaSistrix style={{color: 'red' , fontSize: '20px' }}/>  
                      </Button>

                     {/** 
                      * 
                      
                       <Button onClick={() => show()} marginRight={8} 
                      outline theme="dark" className="mb-2 mr-1" >
                        =>
                      </Button>

                     */}

                     
                        <Dialog 
                        //title="第一个 Dialog"
                          visible={Visible}
                          draggable={true}
                        // width="200px"
                          onOk={() => {
                            setVisible(false)
                          }}
                          onCancel={() => {
                            setVisible(false)
                          }}
                        >

                          <EditMiseService />
                        </Dialog>




                    </ButtonGroup>

      <InputGroup size="sm" className="ml-auto">

{ /** VOI apres comment gerer les deux InputGroup  */}

       <InputGroup size="sm" className = "d-flex my-auto date-range">


    <InputGroupAddon type="append">

            
          <InputGroupText> 
          <FaSistrix style={{color: 'black' , fontSize: '15px' }}/>  
          </InputGroupText>

        </InputGroupAddon>


        <DatePicker
          size="sm"
          selected={DateDebut}
          onChange={ChangeDateDebut}
          placeholderText="Date debut"
          dropdownMode="select"
          highlightDates={[today, yesterday, twoDaysAgo]}
          className="text-center"
        />
        
        <DatePicker
          size="sm"
          selected={DateFin}
          onChange={ChangeDateFin}
          placeholderText="Date Fin"
          dropdownMode="select"
          className="text-center"
        />

<label htmlFor="Région" style={{paddingRight: 10, paddingLeft: 10}} ></label>


    </InputGroup>


      </InputGroup>


    </ButtonToolbar>




     <div>
        <br/>
      </div>


         {/**
          *
          * <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-warning" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>


          */}

          </Col>
                
  

       </Row>




                <MDBDataTableV5  responsive autoWidth striped
                    hover
                    entriesOptions={[10, 20, 30, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    small
                    bordered
                    striped
                   // scrollX
                   //scrollY
                    
                    
                   exportToCSV proSelect 
                    fullPagination 
                    filter='office'  
                    searchTop searchBottom={false}

                    data={datatable}
                    checkbox
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                    showLogs2(e);
                    }}

                />


            </CardBody>
            </CardHeader>
            </Card>

   




 {
     //'oooooooooooooooooooooAAA \n' + JSON.stringify(checkbox1).name
     Store2.session("LigneSelect", checkbox1.idouvrage) 
 }




  </Container>

    </>
  );

}else{

  return(
    <>
           <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Mise en service" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>



    <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }}> 
     <h4> CHARGEMENT EN COURS ...  </h4>
     <BallTriangle color="#00BFFF" height={80} width={80} />

  </div>


    </Container>
    
    </>
  )



}

}

export default ListMiseService;