import 'uxcore/assets/iconfont.css';
import 'uxcore/assets/orange.css';

import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
 
import routes from "./routes";  
import withTracker from "./withTracker"; 
import MaconUser from '../src/Pages/MaconUser';     
     
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
 import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Lien =  process.env.PUBLIC_URL 
// alert( Lien )
export default () => (
  <Router basename={ Lien || ""}>
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
          //  exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}

        <Route            
             path= {Lien + '/'}
             exact = {true}
             component= {MaconUser} 
            
           />
    
    </div>
  </Router>
);
