// import Store2 from "../views/Storage/Store2"; 
import Store2 from "store2"; 

export default function() {
  
  const TypeConnexion = Store2.session("TypeConnexion" ) 

 
  
    const RecupStat  =  () => {

    }

  // 1 ////////////  SAUVEGARDE MENU

  if( TypeConnexion === "Opérateur Garbal"){

    return [
      {
        title: "Accueil",
        to: "/Accueil",
  
        htmlAfter: ""
      },
      // {
      //   title: "Paramètres",
      //   to: "/Parametrese",
  
      //   htmlAfter: ""
      // },
      {
        title: "Opérateur garbal",
        to: "/GARBAL",
  
        htmlAfter: ""
      },
/** 
      {
        title: "Forms & Components",
        htmlBefore: '<i class="material-icons">view_module</i>',
        to: "/components-overview",
      },
*/
  
    ];


  } else if ( TypeConnexion === "Administrateur") {

    return [

      {
        title: "Accueil",
        to: "/Accueil",
  
        htmlAfter: ""
      },
      {
        title: "Mise en service",
        to: "/Mise_en_service",
  
        htmlAfter: ""
      },
      {
        title: "SAV & RD",
        to: "/SAV",
  
        htmlAfter: ""
      },
      {
        title: "Suivi classique",
        to: "/Suivi_Classic",
  
        htmlAfter: ""
      },
      {
        title: "Recep.provisoire",
        to: "/Recep_provisoire",
  
        htmlAfter: ""
      },
      {
        title: "Suivi hors site",
        to: "/List_Suivi_Hors_Site",
  
        htmlAfter: ""   
      },
      
      // {
      //   title: "Paramètres",
      //   to: "/Parametrese",
  
      //   htmlAfter: ""
      // },
      {
        title: "Opérateur garbal",
        to: "/GARBAL",
  
        htmlAfter: ""
      },
      {
        title: "Compte utilisateur",
        to: "/UserParams",
  
        htmlAfter: ""
      },

      // {
      //   title: "Gestion des ouvrages",
      //   to: "/ListeOuvrages",
  
      //   htmlAfter: ""
      // },


      /**
       
       {
        title: "Tableau",
  
        to: "/Tableau",
      },
      {
        title: "Maconnexion",
  
        to: "/Maconnexion",
      }
      ,
      {
        title: "Inscription",
  
        to: "/Inscription",
      },
      {
        title: "Edition",
  
        to: "/Edition",
      },
      {
        title: "Tableau",
  
        to: "/Tableau",
      },
  
  
       */
  
  
  
  
  /** 
  
      {
        title: "Blog Posts",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/blog-posts",
      },
      {
        title: "Add New Post",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/add-new-post",
      },
      {
        title: "Forms & Components",
        htmlBefore: '<i class="material-icons">view_module</i>',
        to: "/components-overview",
      },
      {
        title: "Tables",
        htmlBefore: '<i class="material-icons">table_chart</i>',
        to: "/tables",
      },
      {
        title: "User Profile",
        htmlBefore: '<i class="material-icons">person</i>',
        to: "/user-profile-lite",
      },
      {
        title: "Errors",
        htmlBefore: '<i class="material-icons">error</i>',
        to: "/errors",
      }
  
  */
  
  
    ];



  } else if ( TypeConnexion === "Partenaire") {

    return [

      {
        title: "Accueil",
        to: "/Accueil",
  
        htmlAfter: ""
      },
      {
        title: "Mise en service",
        to: "/Mise_en_service",
  
        htmlAfter: ""
      },


  
    ];
  
  
  } else if ( TypeConnexion === "Utilisateur") {

    return [

      {
        title: "Accueil",
        to: "/Accueil",
  
        htmlAfter: ""
      },
      {
        title: "Mise en service",
        to: "/Mise_en_service",
  
        htmlAfter: ""
      },
      {
        title: "SAV",
        to: "/SAV",
  
        htmlAfter: ""
      },
      {
        title: "Suivi classique",
        to: "/Suivi_Classic",
  
        htmlAfter: ""
      },
      {
        title: "Recep.provisoire",
        to: "/Recep_provisoire",
  
        htmlAfter: ""
      },
      {
        title: "Suivi hors site",
        to: "/List_Suivi_Hors_Site",
  
        htmlAfter: ""   
      },
      
      // {
      //   title: "Paramètres",
      //   to: "/Parametrese",
  
      //   htmlAfter: ""
      // },

      /**
       
       {
        title: "Tableau",
  
        to: "/Tableau",
      },
      {
        title: "Maconnexion",
  
        to: "/Maconnexion",
      }
      ,
      {
        title: "Inscription",
  
        to: "/Inscription",
      },
      {
        title: "Edition",
  
        to: "/Edition",
      },
      {
        title: "Tableau",
  
        to: "/Tableau",
      },
  
  
       */
  
  
  
  
  /** 
  
      {
        title: "Blog Posts",
        htmlBefore: '<i class="material-icons">vertical_split</i>',
        to: "/blog-posts",
      },
      {
        title: "Add New Post",
        htmlBefore: '<i class="material-icons">note_add</i>',
        to: "/add-new-post",
      },
      {
        title: "Forms & Components",
        htmlBefore: '<i class="material-icons">view_module</i>',
        to: "/components-overview",
      },
      {
        title: "Tables",
        htmlBefore: '<i class="material-icons">table_chart</i>',
        to: "/tables",
      },
      {
        title: "User Profile",
        htmlBefore: '<i class="material-icons">person</i>',
        to: "/user-profile-lite",
      },
      {
        title: "Errors",
        htmlBefore: '<i class="material-icons">error</i>',
        to: "/errors",
      }
  
  */
  
  
    ];

  }else{

    return []
  }
  
  // 1  ///////////  SAUVEGARDE MENU



  // 2 - /////////////////  SAUVEGARDE MENU MALI NIGER

  
//  if ( TypeConnexion === "Administrateur") {

//   return [

//     {
//       title: "Accueil",
//       to: "/Accueil",

//       htmlAfter: ""
//     },

//      {
//       title: "Gestion des ouvrages",
//         to: "/ListeOuvrages",
  
//           htmlAfter: ""
//     },
//     {
//       title: "Ouvrages en attente",
//         to: "/ListActiveOuvrage",
  
//           htmlAfter: ""
//     },
//     {
//       title: "Créer un ouvrage",
//         to: "/EditCreation",
  
//           htmlAfter: ""
//     },
//     // {
//     //   title: "Suivi Intermédaire",
//     //     to: "/EditIntermediare",
  
//     //       htmlAfter: ""
//     // },
//     {
//       title: "Compte utilisateur",
//         to: "/UserParams",
  
//           htmlAfter: ""
//     },


  

//   ];



// }  else if ( TypeConnexion === "Utilisateur") {

//     return [

//       {
//         title: "Accueil",
//         to: "/Accueil",
  
//         htmlAfter: ""
//       },

//       {
//         title: "Gestion des ouvrages",
//           to: "/ListeOuvrages",
    
//             htmlAfter: ""
//       }

  
//     ];

// } else if ( TypeConnexion === "Entreprise") {

//   return [

//     {
//       title: "Accueil",
//       to: "/Accueil",

//       htmlAfter: ""
//     },

//     {
//       title: "Gestion des ouvrages",
//         to: "/ListeOuvrages",
  
//           htmlAfter: ""
//     },
//     {
//       title: "Ouvrages en attente",
//         to: "/ListActiveOuvrage",
  
//           htmlAfter: ""
//     },
//     {
//       title: "Créer un ouvrage",
//         to: "/EditCreation",
  
//           htmlAfter: ""
//     },



//   ];

// }
// else{

//   return []
// }



  // 2 /////////////////  SAUVEGARDE MENU MALI NIGER






}



