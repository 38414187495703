import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, DatePicker, InputGroupText,
    CardHeader, CardBody, FormSelect,label, InputGroupAddon,
    ButtonToolbar,ButtonGroup,InputGroup,Modal, ModalBody, ModalHeader,
    Button,FormInput } from "shards-react";

// import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";


import { MDBDataTable,
        MDBDataTableV5 ,
       } from 'mdbreact';

// import { MDBDatePickerV5 } from 'mdb-ui-kit';
 import moment from 'moment';


import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import PageTitle from "../../components/common/PageTitle"; 
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params"; 
import { Redirect  } from "react-router-dom";
//  import { FontAwesomeIcon   } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaHeart, FaSyncAlt } from "react-icons/fa";

//import RefreshIcon from '@mui/icons-material/Refresh';

import { Audio, BallTriangle } from  'react-loader-spinner'

// import { IconName } from "react-icons/fa"; 
const  ListSuiviHorsSite  = (props) => {

  // let history = useHistory();
  // const  Entete =  global.token  
  const ligneUserParams  =   Store2.session("ligneUserParams")
  const Token = Store2.session("token" ) 
  const TypeConnexion = Store2.session("TypeConnexion" ) 


  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        name: "Johson",
        amount: 30000,
        sex: 'M',
        is_married: true
    },
    {
        name: "Monika",
        amount: 355000,
        sex: 'F',
        is_married: false
    },
    {
        name: "John",
        amount: 250000,
        sex: 'M',
        is_married: false
    },
    {
        name: "Josef",
        amount: 450500,
        sex: 'M',
        is_married: true
    }
];

const dataSet2 = [
    {
        name: "Johnson",
        total: 25,
        remainig: 16
    },
    {
        name: "Josef",
        total: 25,
        remainig: 7
    }
];


const [Open,setOpen] = useState( false )

/////////// FIN EXCEL EXPORT DATA

  const [Content, setContent] = useState()
  // const [datatable, setDatatable] = useState({columns:[], rows:[] })
  //Store2.session("ligne", []); 
  const [datatable, setDatatable] = useState()
  const [MaSelection, setMaSelection] = useState()
  const [Region, setRegion] = useState('')
  const [DateDebut, setDateDebut] = useState()
  const [DateFin, setDateFin] = useState()
  const [Motif, setMotif] = useState('')
  const [MotifAppel,setMotifAppel] = useState('')

  //const LigneClick = "GANSBEOGO"


  const [Chargement, setChargement] = useState(false);
  const [MonFetch, setMonFetch] = useState([]);
  const [Monerror, setMonerror] = useState(null);
  
  



  useEffect(() => {
    // loadData();
    // MesDonnees( Store2.session("ligneSAV") )
    fetchData()
  
  }, []);
  
  
  
  
  const fetchData = async () => {
    setChargement(true);
    setMonerror(null);
  
    try {
      const response = await axios.get( Params.Api + '/api/dsh_UserParams/?token=' + Token  );
      setMonFetch(response.data);
      MesDonnees(response.data )  
      // alert( response.data.length)
    } catch (error) {
      setMonerror(error.message);
    }
  
    setChargement(false);
  };
  
  


// http://192.168.223.1:4200/api/region/?token=
//	axios.get( Params.Api + '/api/region/?token=' + Token )
  


const loadData = async () => {
  const Token = Store2.session("token" )
  const response = await fetch( Params.Api + '/api/dsh_UserParams/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneUserParams", contentData); 

 // MesDonnees(contentData)
}



const MesDonnees =  (data) => {
  setDatatable()
  setDatatable({
    columns: [   
      {
        label: 'Id',
        field: 'user_id',
        width: 10,
        sort: 'asc',
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'id',
        },
      },
      {
        label: 'Prénom',
        field: 'first_name',
        width: 50,
      },
      {
        label: 'Nom',
        field: 'last_name',
       // sort: 'asc',
        width: 50,
      },
      {
        label: 'Email',
        field: 'email',
       // sort: 'asc',
        width: 150,
      },
      {
        label: 'Date_création',
        field: 'created_date',
       // sort: 'asc',
        width: 100,
      },
      {
        label: 'Téléphone',
        field: 'phone_number',
       // sort: 'asc',
        width: 50,
      },
      {
        label: 'Type_connnexion',
        field: 'type_connnexion',
       // sort: 'asc',
        width: 50,
      },  
      // {
      //   label: 'Status',
      //   field: 'useractive',
      //  // sort: 'asc',
      //   width: 10,
      // }, 
  
    ],
    rows: data,
    
  })
}


const loadDataParam = async () => {


  const MesParams = {
    Region :  Region,
    //Province :  Province,
    DateDebut : DateDebut,
    DateFin :  DateFin,
    Motif : MotifAppel,

  }

  //  alert(JSON.stringify( MesParams ))

  const Token = Store2.session("token" )
  const Lien = Params.Api +  "/api/dsh_UserParams/?token=" + Token


  const DateD  =  moment(DateDebut).format("YYYY-MM-DD")
  const DateF  =  moment(DateFin).format("YYYY-MM-DD")
 const URL = ""

//   if(  MotifAppel ==="" ){
//     alert( "Selectionnez au moins le motif SVP")
//    ///////////URL = `http://192.168.223.1:4200/api/dsh_garbalparams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF
// return
//   }


try {
  
  const contentData = await axios.get( Params.Api +  `/api/dsh_UserParams/?token=` + Token + `&Motif=` + MotifAppel + `&DateDebut=` + DateD + `&DateFin=` + DateF)



  setContent( contentData.data )
  Store2.session("ligneUserParams", contentData.data); 
  //setDatatable()
  MesDonnees(contentData.data)


} catch (error) {
  alert("Verifier votre connexion SVP.");
}




}


const Raffraichir = async () => {

  //loadData();

  const Token = Store2.session("token" )
  const response = await fetch( Params.Api +  '/api/dsh_UserParams/?token=' + Token);
   const contentData = await response.json();
  setContent( contentData )
  Store2.session("ligneUserParams", contentData); 


  MesDonnees(contentData )

}



const Supprimer = async () => {

  if (   TypeConnexion === "Utilisateur"){
    alert(Params.infoDroitInsuffisant)  
    return
  }

  
  const Token = Store2.session("token" )

//  Test de confirmation de selection
  if( MaSelection ){
        
          let Reponnse = window.confirm("Êtes-vous sûr de vouloir supprimer cette ligne ?");
// Test de confirmation de suppression
          if (Reponnse ) {
        
                    try {
                      const contentData = await axios.put( Params.Api +  `/api/dsh_UserParams_sup/?token=` + Token + `&id=` + checkbox1.user_id )
                    
                      const Res = JSON.stringify(contentData.data.Error)

                  //  alert ( Res  )
                    
                      if (Res  === true ){
                        alert("Suppression impossible conctacter Admin")
                        
                      
                      }else{

                        alert("Suppession terminée avec succès")
                      }
                  
                      Raffraichir()
        
                    
                    } catch (error) {
                      alert("Verifier votre connexion SVP.");
                    }
                    
          } else {
            // Do nothing!
        
          }
  
    



    
  }else{
    alert("Selctionner un élément dans la liste SVP")
  }



  



}



const Modifier = () => {
//	e.preventDefault();
//const  history = useHistory();
// alert( 'ID = ' + MaSelection)

if( MaSelection ){
  props.history.push("/EditUserParams")
}else{
  alert("Selctionner un élément dans la liste")
}

}


const Nouveau = () => {
  //	e.preventDefault();
  //const  history = useHistory();
  // alert( 'ID = ' + MaSelection)

  Store2.session("ligneSelectUserParams", "")
  props.history.push("/EditUserParams")


}


const [checkbox1, setCheckbox1] = useState('');
const showLogs2 = (e) => {
  setCheckbox1(e);
  setMaSelection(e.user_id)
  Store2.session("ligneSelectUserParams", e)
};

const Lignes =  Store2.session("ligneUserParams")

//alert (JSON.stringify(Lignes))


const ChangeDateDebut = (value) => {
setDateDebut( new Date(value) )
}

const ChangeDateFin  = (value) => {
  setDateFin( new Date(value) )
}




const MaRegion =   Store2.session("Region")
const Donnees= Store2.session("ligneUserParams")
const MotifGarbal =   Store2.session("MotifGarbal")
// alert ( JSON.stringify( Donnees))


if (datatable){

  
  if ( Token  === undefined  ||  Token  === "" || TypeConnexion === "Partenaire"  || TypeConnexion === "Opérateur Garbal" ) {
    //Affichage de la redirection
   // alert('  Erreur : Verifier votre Email / Mot de passe. ')
    return <Redirect to='/'/>;
  
  }
  



  const today = new Date();
  const yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
  const twoDaysAgo = (d => new Date(d.setDate(d.getDate() - 2)))(new Date());





  return (
    <>
       <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Comptes utilisateurs" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>

            
            <Card small className="mb-4">
            <CardHeader className="border-bottom">
            <CardBody className="p-0 pb-3">


            <Row>   

                                <Col  md="12">

         <ButtonToolbar>

                    <ButtonGroup size="sm">

                    
 {
   /** 
    
                      <Button onClick={() => 
                    ExportModal()
                  // ExportChoix()
                     } marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Export
                      </Button>
                      < ModalContent />
                      
   */
 }
                      

                      
                      <Button onClick={() => Nouveau()} marginRight={8} 
                      outline small theme="dark" className="mb-2 mr-1" >
                      Nouveau
                      </Button>


                      
                      
                      <Button onClick={() => Modifier()}
                      marginRight={8}  outline theme="success" className="mb-2 mr-1">
                        Modifier
                      </Button>

                      <Button onClick={() => Supprimer()} marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Supprimer
                      </Button>

                      <Button  onClick={() => Raffraichir()} marginRight={8} 
                      outline theme="primary" className="mb-2 mr-1" >

                      {/* <FaHeart style={{color: 'red', fontSize: '50px'}}/> */}
                      <FaSyncAlt style={{color: 'blue' , fontSize: '20px' }}/>   
                      {/* <FontAwesomeIcon icon="fa-solid fa-arrows-rotate" /> */}

                      </Button>
                      
                      
                    </ButtonGroup>



    </ButtonToolbar>




     <div>
        <br/>
      </div>

         {/**
          *
          * <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-danger" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-warning" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="spinner-grow text-info" role="status">
        <span className="sr-only">Loading...</span>
      </div>


          */}

          </Col>
                
  

       </Row>




                <MDBDataTableV5  responsive autoWidth striped
                    hover
                    entriesOptions={[10, 20, 30, 50, 100]}
                    entries={10}
                    pagesAmount={4}
                    small
                    bordered
                    striped
                   // scrollX
                   //scrollY
                    
                    
                   exportToCSV proSelect 
                    fullPagination 
                    filter='office'  
                    searchTop searchBottom={false}

                    data={datatable}
                    checkbox
                    headCheckboxID='id2'
                    bodyCheckboxID='checkboxes2'
                    getValueCheckBox={(e) => {
                    showLogs2(e);
                    }}

                />


            </CardBody>
            </CardHeader>
            </Card>

   




 {
     //'oooooooooooooooooooooAAA \n' + JSON.stringify(checkbox1).name
    /// Store2.session("LigneSelect", checkbox1.user_id) 
 }




  </Container>

    </>
  );

}else{

  return(
    <>
           <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Compte utilisateur" subtitle="Liste de saisies" className="text-sm-left" />
    </Row>

    <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }}> 
     {/* <h4> CHARGEMENT EN COURS ...  </h4> */}
     <BallTriangle color="#00BFFF" height={80} width={80} />

  </div>
  
    </Container>
    
    </>
  )



}

}

export default ListSuiviHorsSite;