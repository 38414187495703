import React, { useState, useEffect } from 'react';
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  DatePicker,
  Alert,
} from "shards-react";
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../Config/Params"; 
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select'
import { Redirect, Link  } from "react-router-dom";

import { Button as MonButton, Switch } from 'uxcore';


			
const F_UserParams = (props) =>{

const Token = Store2.session("token" ) 
const TypeConnexion = Store2.session("TypeConnexion" ) 


const LigneUserParams =  Store2.session("ligneSelectUserParams")
 // alert( JSON.stringify(LigneUserParams) )
//  alert( JSON.stringify ( LigneUserParams.useractive ))

const [IdUser,setIdUser] = useState(0)

const [MonID,setMonID] = useState(  LigneUserParams ? LigneUserParams.user_id : 0)
const [Nom,setNom] = useState(  LigneUserParams ? LigneUserParams.last_name : '')
const [Prenom,setPrenom] = useState(  LigneUserParams ? LigneUserParams.first_name : '')
const [Email,setEmail] = useState(  LigneUserParams ? LigneUserParams.email : '')
const [Telephone,setTelephone] = useState(  LigneUserParams ? LigneUserParams.phone_number : '')
const [Observation,setObservation] = useState(  LigneUserParams ? LigneUserParams.observation : '')


const [Password,setPassword] = useState( '')
const [Password2,setPassword2] = useState(  '')
const [TypeCon,setTypeCon] = useState(  LigneUserParams ? LigneUserParams.type_connnexion : '')
const [Active,setActive] = useState(  LigneUserParams ? LigneUserParams.useractive : null )


const [checked, setchecked] = useState( Active )



const ModificationData = async (Donnees) => {


	axios.post( Params.Api + '/api/dsh_UserParams_modif/?token=' + Token , Donnees )
		
  
	.then(function (response) {
	window.location.href = '/UserParams' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }

  
const InsertionData = async (Donnees) => {
//	alert("-- ok InsertionData ")

	//axios.post( Params.Api + '/inscription', Donnees )
	axios.post( Params.Api + '/api/inscription/?token=' + Token , Donnees )

	.then(function (response) {

        
        const Donnees = response.data
     //   alert( Donnees)
       // return

        if ( Donnees.existe ){
            alert( "Ce email existe déjà.")
        }else{
            window.location.href = '/UserParams' 

        }
	
	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	 // alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	  alert(`Erreur API  : constacter l'administrateur \n ` + error );
	});


  }



const Enregistrer = () => {
  //this.props.history.push("/Accueil")
 //  let  Activation = Active ? 1 : 0

  if (   TypeConnexion === "Utilisateur"){
    alert(Params.infoDroitInsuffisant)  
    return
  }

if ( Password != Password2){
    alert( "Les 2 mots de passe ne sont pas identiques")  
    return
}

  if( MonID === 0 ){
  // alert ('Nouveau')
//  moment(DateRdv).format("YYYY-MM-DD HH:mm:ss"),



	var Donnees = {
		Nom  : Nom,
		Prenom  : Prenom , 	
		Email  : Email, 
        TypeCon  : TypeCon, 
        Password  : Password, 
        Telephone  : Telephone, 
		Observation  : Observation, 
		useractive : Active
	 
	  };

	 // alert ( Donnees )

	InsertionData( Donnees )

  }else{
	// alert ('Modification')
	var Donnees = {
		user_id  : MonID,
		Nom  : Nom,
		Prenom  : Prenom , 	
		Email  : Email, 
        TypeCon  : TypeCon, 
        Password  : Password, 
        Telephone  : Telephone, 
		Observation  :Observation, 
		useractive : Active
	 
	  };
	
	//  alert ( JSON.stringify( Donnees ) )
	  ModificationData( Donnees )

  }


}


// ----------------------------------------



  const options = Store2.session("ouvrageinfo" )
  const ouvrageinfoall = Store2.session("ouvrageinfoall" )
  


//-----------------------------


const  title = "Détails de saisie"

  return (
	  <Card small className="mb-4" >
	  

            { /**
             <Alert theme="success">
                    aaaaaaaaaaaa
                </Alert>

             */}
		
		<ListGroup flush>
		
		  <ListGroupItem className="p-3">
			<Row>
			  <Col>
				<Form  // onSubmit={this.onEnvoyer}
				>


				  <Row form>

					{/* Nom */}
					<Col md="4" className="form-group">
					  <label htmlFor="Nom">Nom </label>
					  <FormInput
						id="Nom"
						placeholder="Nom"
						value= {Nom}
						onChange={(e) => {

							 	setNom(e.target.value)
				

						}}
						
					  />
					</Col>

					{/* Prenom */}
					<Col md="4" className="form-group">
					  <label htmlFor="Prenom">Prénom(s)</label>
					  <FormInput
						id="Prenom"
						placeholder="Prenom"
						/* required */
						value={Prenom}
						onChange={(e) => {

							setPrenom(e.target.value)

						}}
					  />
					</Col>

					{/* Email */}
					<Col md="4" className="form-group">
					<label htmlFor="Email">Email</label>
					  <FormInput
						id="Email"
						placeholder="Email"
						/* required */
						value={Email}
						onChange={(e) => {
	
							 	setEmail(e.target.value)

							
						}}
					  />
					</Col>
				  </Row>



				  <Row>

					{/* Telephone */}
					<Col md="3" className="form-group">
					<label htmlFor="Telephone">Téléphone</label>
					  <FormInput
						id="Telephone"
						placeholder="Telephone"
						value={Telephone}
						onChange={(e) => {

						 	setTelephone(e.target.value)

							
						}}
					  />
					</Col>

					{/* TypeConnnexion */}
					<Col md="3" className="form-group">
					  <label htmlFor="TypeConnnexion">Type connexion</label>
                      <FormSelect id="TypeConnnexion"

					  value={TypeCon} 	
						onChange={(e) => {
							setTypeCon(e.target.value)
						}}
					  >
							<option>Choisir le type de connexion ...</option>
							<option> Administrateur </option>
							<option> Utilisateur </option>
							<option> Android </option>
							<option> Entreprise </option>
						  </FormSelect>

					</Col>

					{/* Password */}
					<Col md="3" className="form-group">
					<label htmlFor="Password">Mot de passe</label>
					  <FormInput
						id="Password"
						placeholder="Password"
						type="password"
						value={ Password}
						onChange={(e) => {
								setPassword(e.target.value)
				
							
						}}
					  />
					</Col>
					
					{/* Password */}
					<Col md="3" className="form-group">
					<label htmlFor="Password2">Confirmer le mot de passe</label>
					  <FormInput
						id="Password2"
						placeholder="Password2"
						type="password"
						value={Password2}
						onChange={(e) => {
                            setPassword2(e.target.value)
				
							
						}}
					  />
					</Col>
				  </Row>
 
				  <Row form>

				{/* Observation */}
				<Col md="12" className="form-group">
				{/* <Switch checkedChildren=" Active 显示" unCheckedChildren="  显示  Desactive" disabled={false} /> */}

				<Switch
					checked={checked}
					checkedChildren="Active &nbsp;&nbsp;&nbsp;"
					unCheckedChildren=" &nbsp; Desactive "
					// className="kuma-switch-no-text" 
					onChange={() => {
					  // alert(   !Active )
						setActive(  !checked )
						setchecked(  !checked )
						
					}}
      			  />


					</Col>
					</Row>


				  <Row form>
				  
					{/* Observation */}
					<Col md="12" className="form-group">
					  <label htmlFor="Observation">Observation</label>
					  <FormTextarea id="Observation" rows="5" 
					  value= {Observation}
						onChange={(e) => {
							setObservation(e.target.value)
						}}	
					  />

		
		
					</Col>
				  </Row>



				  <Button  onClick={ () => Enregistrer() } 
                                           marginRight={8} theme="success">
                  Enregistrer
                </Button>

				
				<Button onClick={() =>  window.location.href = '/UserParams'  } marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Annuler
                      </Button>

				</Form>
			  </Col>
			</Row>
		  </ListGroupItem>
		</ListGroup>
	
	
	  </Card>

        )

}



  
export default F_UserParams;
