import React from "react";
import { Container, Button } from "shards-react";

const Errors = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>500</h2>
        <h3>Un problème s'est produit!</h3>
        <p>Il y a eu un problème de notre côté. Veuillez réessayer plus tard.</p>
        <Button pill>&larr; Retourner</Button>
      </div>
    </div>
  </Container>
);

export default Errors;
