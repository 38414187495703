import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  DatePicker,
  Alert,
} from "shards-react";
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../../Pages/Config/Params"; 
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select'
import { Redirect, Link  } from "react-router-dom";

 
import { Album, Dialog, Switch  } from 'uxcore';
import { Button as MonButton } from 'uxcore';

import Swal from 'sweetalert2'

const { Photo } = Album;


			
const F_MiseEnService = (props) =>{

const Token = Store2.session("token" ) 
let ListeRegion  = Store2.session("Region") 
const [ListeProvince,setListeProvince] = useState( [] )
const [ListeCommune,setListeCommune] = useState([])


const TypeConnexion = Store2.session("TypeConnexion" ) 
const UserId = Store2.session("UserId" ) 
  

const LigneReceptProv =  Store2.session("ligneSelectReceptProv")
 // alert( JSON.stringify(LigneReceptProv.latouvrage) )
 // const CnxLat = JSON.stringify(LigneReceptProv.cnxlat)
//  GPS_Lat  GPS_Long  ` + GPS_Lat + "&lat=" + GPS_Long + "" 

// const GPS_Lien = 'http://gps.biosynchropnbbf.net/?lon=' + GPS_Long + '&lat='+ GPS_Lat
 /// alert( JSON.stringify ( LigneReceptProv ))
const [IdOuvrage,setIdOuvrage] = useState(0)

const [MonID,setMonID] = useState(  0)
const [Region,setRegion] = useState('')
const [Province,setProvince] = useState( '')
const [Commune,setCommune] = useState(  '')
const [Village,setVillage] = useState( '')
const [Observation,setObservation] = useState(  '')

const [PlanCode,setPlanCode] = useState( '')
const [ExPlanCode, setExPlanCode] = useState( '')
const [PlanSize, setPlanSize] = useState('')
const [TypeOuvrage,setTypeOuvrage] = useState(  '')
// const [NomPrenClient,setNomPrenClient] = useState(  LigneReceptProv ? LigneReceptProv.Nomprenomclient : '')
const [NomClient, setNomClient] = useState('')
const [PrenomClient, setPrenomClient] = useState('')

// const [NomPrenMacon,setNomPrenMacon] = useState(  LigneReceptProv ? LigneReceptProv.Nomprenommacon : '')
const [NomMacon, setNomMacon] = useState('')
const [PrenomMacon, setPrenomMacon] = useState('')
const [NomEcb,setNomEcb] = useState('')
const [CodeEcb, setCodeEcb] = useState( '')


const [EtatOuvrage,setEtatOuvrage] = useState(  '')
const [ObsEtat,setObsEtat] = useState( '')

// alert( LigneReceptProv.datefintravaux )
const [DateFinTrx,setDateFinTrx] =  useState(null)
const [Partenaire, setPartenaire] = useState(  '')


//   alert( "DateFinTrx = " +  new Date(Date.parse( DateFinTrx ))  )  
//alert( "DateFinTrx = " + Date3 )  

const [SexClient, setSexClient] = useState( '')
const [TelClient1, setTelClient1] = useState( '')
const [TelClient2, setTelClient2] = useState( '')
const [NbFemme, setNbFemme] = useState('')
const [TailleMenage, setTailleMenage] = useState('')


const [TelMacon1, setTelMacon1] = useState( '')
const [TelMacon2, setTelMacon2] = useState( '')

const [NomControleur, setNomControleur] = useState( '')
const [PrenomControleur, setPrenomControleur] = useState( '')

const [NomBio, setNomBio] = useState( '')
const [ModeleBio, setModeleBio] = useState( '')


const [DateCtrlQualite,setDateCtrlQualite] =   useState(null)

 // alert( "DateCtrlQualite = " + DateCtrlQualite )  

const [DateReceptProv,setDateReceptProv] = useState(null)
//const [DateMiseService,setDateMiseService] = useState( LigneReceptProv.datemiseservice != "0000-00-00 00:00:00" ?  new Date (LigneReceptProv.datemiseservice)   : new Date () )
const [DateMiseService,setDateMiseService] =  useState(null)
const [DateReceptdef,setDateReceptdef] = useState(null)


const [DateSav1,setDateSav1] = useState(null)
const [DateSav2,setDateSav2] = useState(null)
const [DateSav3, setDateSav3] = useState(null)


const [HangareExite,setHangareExite] = useState( '')
const [Certificat,setCertificat] =useState( '')
const [Conlatrine,setConlatrine] = useState( '')
// alert( Conlatrine )
const [EtatLatrine,setEtatLatrine] = useState( '')

const [GPS_Lat,setGPS_Lat] = useState( '')
const [GPS_Long,setGPS_Long] = useState( '')

const [Active,setActive] = useState(0)


const [checked, setchecked] = useState( Active )


const GPS_Lien = 'http://gps.biosynchropnbbf.net/?lon=' + GPS_Lat + '&lat='+ GPS_Long

//const GPS_Lien = '/?lon=' + GPS_Lat + '&lat='+ GPS_Long 
// alert( JSON.stringify ( GPS_Lien ))

// alert( LigneReceptProv.datemiseservice )




const Messages  =  (Titre, Message, Icone , ButtonText ) => {

	Swal.fire({
	  title: Titre,
	  text: Message ,
	  icon: Icone ,
	  confirmButtonText: ButtonText
	})
  }



const onEnvoyer =(e) => {
        e.preventDefault();
        const MesDonnees = {
            id:  110,
            Adresse: "Adresse",

        }
		/* alert(this.state.Nomsociete); */
		
        if ( true ) {

        axios.post('http://localhost:3310/inscription', MesDonnees)
        .then(res => console.log(res.data));
        

		return (

			  <Alert className="mb-0">
				<i className="fa fa-info mx-2"></i> Enregistrement terminé avec succès 
			  </Alert>
	
	        );
       }else alert ('Verifier la saisie des champs obligatoires SVP');
  
}



const ModificationData = async (Donnees) => {


	axios.post( Params.Api + '/api/dsh_receptprov_modif/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	 window.location.href = '/ListeOuvrages' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }

  
const InsertionData = async (Donnees) => {


axios.post( Params.Api + '/api/dsh_receptprov_creation/?token=' + Token , Donnees )
		
  
	.then(function (response) {

		(TypeConnexion != "Entreprise"  ) ? 
	    window.location.href = '/ListeOuvrages' 
		: window.location.href = '/ListActiveOuvrage' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


	Messages("Création Ouvrage", "La création de l'ouvrage terminé avec succès " , "success", "OK" )

  }



  
  const VerifSaisie = () => {

	if (   NomClient ==='' ||   PlanCode ===''
	   ||PrenomClient  ==='' || SexClient  ==='' ||  TelClient1  ==='' ||  TelClient2  ==='' ||
	    NomEcb  ==='' || NomMacon ===''    || PrenomMacon ==='' 

	 ||  TelMacon1 ===''  ||  ModeleBio  ==='' ||
	 Region ==='' || Province ==='' || Commune  ===''   || Village  ==='' 




   ){

		// alert('Veuillez remplir tous les champs obligatoires (*) SVP')
		alert('Veuillez remplir tous les champs obligatoires  SVP')
		//setisLoading(0)
		return 0;

	} else {

		return 1;
	}


}



const Enregistrer = () => {
  //this.props.history.push("/Accueil")

  
  if ( TypeConnexion === "Partenaire" ||  TypeConnexion === "Utilisateur"){
	alert(Params.infoDroitInsuffisant)  
	return
}


  if( MonID === 0 ){
// alert ('Nouveau')
//  moment(DateRdv).format("YYYY-MM-DD HH:mm:ss"),


	var Donnees = {
		//IdOuvrage  : IdOuvrage,
		userid :   UserId,
        
        PlanCode : PlanCode,
		// Idmiseenservice  : LigneReceptProv.idmiseenservice,
		Etat  : EtatOuvrage , 
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		typeouvrage  : TypeOuvrage,
		nomptf  : Partenaire,
		plansize : PlanSize,
		
		nomclient : NomClient,
		prenomclient: PrenomClient,

		sexeclient: SexClient,
		tel1client: TelClient1,
		tel2client: TelClient2,
		nombrefemmemenage: NbFemme,
		taillemenage: TailleMenage,
		nomvillage: Village,
		nomcommune: Commune,
		nomprovince: Province,
		nomregion: Region,

		nommacon: NomMacon,
		prenommacon: PrenomMacon,
		tel1Macon: TelMacon1,
		tel2Macon: TelMacon2,
		nomcontroleur: NomControleur,
		prenomcontroleur: PrenomClient,
		nomecb: NomEcb,
		//codeecb: CodeEcb,

	    observation_etat: ObsEtat,  
		modelebiodigesteur : ModeleBio,
		nommodelebiodigesteur : NomBio,


		datefintravaux : moment(DateFinTrx).format("YYYY-MM-DD"),
		datecontrolequalite :  moment(DateCtrlQualite).format("YYYY-MM-DD"),
		datereceptprov : null,
		datemiseservice :null,
		datereceptdef : null,
		etalat  : EtatLatrine,
		cnxlat  : Conlatrine,


		// datereceptprov : moment(DateReceptProv).format("YYYY-MM-DD"),
		// datemiseservice : moment(DateMiseService).format("YYYY-MM-DD"),
		// datereceptdef : moment(DateReceptdef).format("YYYY-MM-DD"),



		hangarexiste : HangareExite,
		certificatexiste  : Certificat,
		active : Active,
		
		// datesav1  : moment(DateSav1).format("YYYY-MM-DD"),
		// datesav2  :  moment(DateSav2).format("YYYY-MM-DD"),
		// datesav3  :  moment(DateSav3).format("YYYY-MM-DD"),

		datesav1  :  null,
		datesav2  :   null,
		datesav3  :  null,


	  };

    // alert( JSON.stringify( Donnees) )

	   
	  const TestChamps = VerifSaisie()


	  if (TestChamps == 1) {
		InsertionData( Donnees )
	  }


  }else{
	// alert ('Modification')
	var Donnees = {
		// IdOuvrage  : LigneReceptProv.idouvrage,
		// // Idmiseenservice  : LigneReceptProv.idmiseenservice,
		// Etat  : EtatOuvrage , 
		// ObservationEtat  : ObsEtat, 
		// Observation  :Observation, 
		// //DateRDv  : moment(DateRdv).format("YYYY-MM-DD"),
		
		// datefintravaux : moment(DateFinTrx).format("YYYY-MM-DD"),
		// datecontrolequalite :  moment(DateCtrlQualite).format("YYYY-MM-DD"),
		// datereceptprov : moment(DateReceptProv).format("YYYY-MM-DD"),
		// datemiseservice : moment(DateMiseService).format("YYYY-MM-DD"),
		// datereceptdef : moment(DateReceptdef).format("YYYY-MM-DD"),
		// etalat  : EtatLatrine,
		// cnxlat  : Conlatrine,
		// hangarexiste : HangareExite,
		// certificatexiste  : Certificat,

		// datesav1  : DateSav1,
		// datesav2  : DateSav2,
		// datesav3  : DateSav3,


		//IdOuvrage  : IdOuvrage,
        
        PlanCode : PlanCode,
		// Idmiseenservice  : LigneReceptProv.idmiseenservice,
		Etat  : EtatOuvrage , 
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		typeouvrage  : TypeOuvrage,
		plansize : PlanSize,
		
		nomclient : NomClient,
		prenomclient: PrenomClient,

		sexeclient: SexClient,
		tel1client: TelClient1,
		tel2client: TelClient2,
		nombrefemmemenage: NbFemme,
		taillemenage: TailleMenage,
		nomvillage: Village,
		nomcommune: Commune,
		nomprovince: Province,
		nomregion: Region,

		nommacon: NomMacon,
		prenommacon: PrenomMacon,
		tel1Macon: TelMacon1,
		tel2Macon: TelMacon2,
		nomcontroleur: NomControleur,
		prenomcontroleur: PrenomClient,
		nomecb: NomEcb,
		//codeecb: CodeEcb,

	    observation_etat: ObsEtat,  
		modelebiodigesteur : ModeleBio,
		nommodelebiodigesteur : NomBio,


		datefintravaux : moment(DateFinTrx).format("YYYY-MM-DD"),
		datecontrolequalite :  moment(DateCtrlQualite).format("YYYY-MM-DD"),
		datereceptprov : moment(DateReceptProv).format("YYYY-MM-DD"),
		datemiseservice : moment(DateMiseService).format("YYYY-MM-DD"),
		datereceptdef : moment(DateReceptdef).format("YYYY-MM-DD"),
		etalat  : EtatLatrine,
		cnxlat  : Conlatrine,
		hangarexiste : HangareExite,
		certificatexiste  : Certificat,
		active : Active,
		
		datesav1  : moment(DateSav1).format("YYYY-MM-DD"),
		datesav2  :  moment(DateSav2).format("YYYY-MM-DD"),
		datesav3  :  moment(DateSav3).format("YYYY-MM-DD"),

		

	 
	  };
	
	  	     
	  const TestChamps = VerifSaisie()


	  if (TestChamps == 1) {
		ModificationData( Donnees )
	  }


  }


 // alert( JSON.stringify(Donnees) )
  //alert( "Mise à jour terminée avec succès")

}


const ChangeDateFinTrx = (value) => {
	setDateFinTrx( new Date(value) )
}


const ChangeDateCtrlQualite = (value) => {
	setDateCtrlQualite( new Date(value) )
}

const ChangeDateReceptProv = (value) => {
	setDateReceptProv( new Date(value) )
}

const ChangeDateMiseService = (value) => {
	setDateMiseService( new Date(value) )
}

const ChangeDateReceptdef = (value) => {
	setDateReceptdef( new Date(value) )
}

// ----------------------------------------

  
const  handleClick = () => {
	//Album.showButton(true)
    Album.show({
      photos: [
        <Photo
          src={ require('../Logo/logo.jpg')}
          key={0}
        />,
        <Photo 
		src={ require('../Logo/Logo_PnbBf.jpg')}  key={1} />,
		<Photo 
		src={ require('../Logo/logo.jpg')}  key={2} />,
        
      ],
    });
  }

// () => <Redirect to="/GARBALw" />

 const  OkInsertion = () => {
	Dialog.info({
	  title: 'BioSynchro',
	  content: 'Insertion Terminé',
	  onOk() {  },
	});
  }
// -------------------------------------------------

const AffichageValeur = (MaListe, Valeur) => {
	//alert( Valeur)
	//alert( MaListe[1].plancode)
	// var Donnees =  MaListe.filter( (item) => {
	// 	return item.plancode == Valeur;
		
	// });

	let Donnees = MaListe.filter(item => item.plancode === Valeur);

	//alert( JSON.stringify(Donnees) )
	// setTypeOuvrage(   Donnees[0].typeouvrage  )
	// setNomPrenClient(  Donnees[0].NomPrenomClient )
	// setNomPrenMacon(  Donnees[0].NomPrenomMacon )
	// setNomEcb(  Donnees[0].NomEcb )

	setRegion(  Donnees[0].Region )
	setProvince(  Donnees[0].Province )
	setCommune(  Donnees[0].Commune )
	setVillage(  Donnees[0].Village )
	setIdOuvrage( Donnees[0].idouvrage )

}






  const options = Store2.session("ouvrageinfo" )
  const ouvrageinfoall = Store2.session("ouvrageinfoall" )
  const ModeleBioDigesteur = Store2.session("ModelBiodigesteur" )
  const LastIdOuvrageden = Store2.session("LastIdOuvrageden" )
   let IdPlanCode = 0

   //  A VERIFIER LA PORTER --  DATE DE MISE EN COMMENT 03/03/2023
//    IdPlanCode = LastIdOuvrageden[0].dernier_id  + 1
//       

// alert( JSON.stringify( LastIdOuvrageden[0].dernier_id + " + 1 = " + IdPlanCode ))

  const PlacodeNew = () => (
	// <Select
	//  options={options}
	//  defaultValue={PlanCode}
	// // onChange={setSelectedOption}
	//  onChange={(x) => {

	// 	//setPlanCode(x.value)
	// 	AffichageValeur( ouvrageinfoall , x.label)
		
	// 	}}


	//   />

	<FormInput
	id="PlanCode"
	// value={ Region + "/" + CodeEcb + "/2023/" }
	value={ PlanCode}
//    type="number"
	style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}

	onChange={(e) => {
		if (MonID === 0){
			const NB = e.target.value
			setPlanCode( NB )
			
		}
		
	}}

/>


  )
  
  const PlacodeModif = () =>{
	  return (

		<FormInput
		id="plancode"
		placeholder="plan code"
		invalid  
		/* required */
		value={PlanCode} 
		onChange={(e) => {

		//		setPlanCode(e.target.value)

		}}
	  />

  )}
  
  
  const AffichePhoto = () => (

	<MonButton  onClick={() => handleClick() } >Afficher </MonButton>
							
	 )
	
	 
	 

//-----------------------------


const  title = "Détails de saisie"

  return (
	  <Card small className="mb-4" >
	  

            { /**
             <Alert theme="success">
                    aaaaaaaaaaaa
                </Alert>

             */}
		
		<ListGroup flush>
		
		  <ListGroupItem className="p-3">
			<Row>
			  <Col>
				<Form  // onSubmit={this.onEnvoyer}
				>
					
				  <Row form>
					{/* plancode */}
					<Col md="6" className="form-group">
					  <label htmlFor="plancode" >Plan code  </label>   
	
	  { MonID === 0 ? PlacodeNew() : PlacodeModif()}

					</Col>
					{/* typeouvrage */}
					<Col md="2" className="form-group">
					  <label htmlFor="typeouvrage">Type ouvrage*</label>
						
						<FormSelect id="TypeOuvrage"
						/* required */
						value={TypeOuvrage} 
						style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
							onChange={(e) => {
								setTypeOuvrage(e.target.value)
							}}
						>
							<option></option>
							<option>Commercial_client</option>
							<option>Démo</option>
							<option>Commercial_partenaire</option>
							<option>Didactique</option>

						</FormSelect>

					</Col>


					{/* Partenaire */}
					<Col md="2" className="form-group">
					  <label htmlFor="Partenaire">Partenaire</label>
						
					  <FormInput
						id="Partenaire"
						// value={ Region + "/" + CodeEcb + "/2023/" }
						value={ Partenaire}
					//    type="number"
						style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}

						onChange={(e) => {
							if (MonID === 0){
								const NB = e.target.value
								setPartenaire( NB )
								
							}
							
						}}

					/>

					</Col>


					{/* PlanSize */}
						<Col md="2" className="form-group">
					  <label htmlFor="PlanSize">PlanSize*</label>
						
					  <FormInput
						id="PlanSize"
						// placeholder="TelClient1"
						// invalid 
						value={PlanSize}
					//    type="number"
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								const NB = e.target.value
								if (!isNaN( NB )) {
									setPlanSize( NB )
								  }else {
									alert( 'Saisir un chiffre valide SVP')
								  }
								
							}
							
						}}
						/*onChange={() => {}} */
					/>

					</Col>

				  </Row>


				  <Row form>
					{/* NomClient */}
					<Col md="2" className="form-group">
					<label htmlFor="NomClient">Nom Client*</label>
					<FormInput
						id="NomClient"
						// placeholder="NomClient"
						// invalid 
						value= {NomClient}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setNomClient(e.target.value)
							}

						}}
						
					/>
					</Col>

					{/* PrenomClient */}
					<Col md="2" className="form-group">
					<label htmlFor="PrenomClient">Prénom Client*</label>
					<FormInput
						id="PrenomClient"
						// placeholder="PrenomClient"
						// invalid 
						value= {PrenomClient}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setPrenomClient(e.target.value)
							}

						}}
						
					/>
					</Col>




						{/* SexeClient */}
			<Col md="2" className="form-group">
			<label htmlFor="SexeClient">Genre Client*</label>
			<FormSelect id="SexeClient"
			/* required */
			value={SexClient} 
			style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
				onChange={(e) => {
					setSexClient(e.target.value)
				}}
			>
				<option></option>
				<option>Homme</option>
				<option>Femme</option>
				

			</FormSelect>
			</Col>



			{/* TelClient1 */}
			<Col md="2" className="form-group">
			<label htmlFor="TelClient1">TelClient1*</label>

			<FormInput
						id="TelClient1"
						// placeholder="TelClient1"
						// invalid 
						value={TelClient1}
					//    type="number"
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								const NB = e.target.value
								if (!isNaN( NB )) {
									setTelClient1( NB )
								  }else {
									alert( 'Saisir un numéro valide SVP')
								  }
								
							}
							
						}}
						/*onChange={() => {}} */
					/>

			</Col>

			{/* TelClient2 */}
			<Col md="2" className="form-group">
			<label htmlFor="TelClient2">TelClient2*</label>

			<FormInput
						id="TelClient2"
						// placeholder="TelClient2"
						// invalid 
						value={TelClient2}
					//    type="number"
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								const NB = e.target.value
								if (!isNaN( NB )) {
									setTelClient2( NB )
								  }else {
									alert( 'Saisir un numéro valide SVP')
								  }
								
							}
							
						}}
						/*onChange={() => {}} */
					/>

			</Col>



		


					</Row>



				{/* NbFemme */}
					<Row form>


					{/* TailleMenage */}
					<Col md="2" className="form-group">
					<label htmlFor="TailleMenage">Taille Ménage</label>

					<FormInput
								id="NbFemme"
								// placeholder="NbFemme"
								// invalid 
								value={TailleMenage}
							//    type="number"
								style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
								onChange={(e) => {
									if (MonID === 0){
										const NB = e.target.value
										if (!isNaN( NB )) {
											if (e.target.value.length <= 2) { 
												// e.preventDefault(); 
												setTailleMenage( NB )
											}
										}else {
											alert( 'Saisir un nombre valide SVP')
										}
										
									}
									
								}}
								/*onChange={() => {}} */
							/>

					</Col>
					

						<Col md="2" className="form-group">
				         	<label htmlFor="NbFemme">Nombre femme</label>

					<FormInput
								id="NbFemme"
								// placeholder="NbFemme"
								// invalid 
								value={NbFemme}
							//     type="number"
							//    min="0"
							//    max="100"
								style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
								onChange={(e) => {
									if (MonID === 0){
										const NB = e.target.value
										if (!isNaN( NB )) {
													if (e.target.value.length <= 2) { 
														// e.preventDefault(); 
														setNbFemme( NB )
													}
											
										}else {
											alert( 'Saisir un nombre valide SVP')
										}
										
									}
									
								}}



								/*onChange={() => {}} */
							/>

				    	</Col>



					{/* NomEcb */}
					<Col md="2" className="form-group">
					<label htmlFor="NomEcb">Nom Ecb*</label>
					<FormInput
						// id="NomEcb"
						// placeholder="NomEcb"
						// invalid 
						/* required */
						value={NomEcb}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setNomEcb(e.target.value)
							}
							
						}}
					/>
					</Col>



					{/* ModeleBiodigesteur */}
					<Col md="2" className="form-group">
					<label htmlFor="ModeleBiodigesteur">Modéle Biodigesteur*</label>
					<FormSelect id="ModeleBiodigesteur"
					/* required */
					value={ModeleBio} 
					style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							let Val = e.target.value
							setModeleBio( Val )

						let Donnees = ModeleBioDigesteur.find(item => item.nommodele === Val);
							setNomBio(Donnees.typemodele )

							// alert( JSON.stringify( ModeleBioDigesteur ) )
							//alert( JSON.stringify(e.target.key ) )
						}}
					>
				
						<option></option>
					{ ModeleBioDigesteur.map((option) =>
					<option key={option.nommodele}>{option.nommodele}</option>
					)}

					</FormSelect>
						
					</Col>



					{/* NomBiodigesteur */}
					<Col md="2" className="form-group">
					<label htmlFor="NomBiodigesteur">Nom Biodigesteur</label>
							
					<FormInput
								id="NomBiodigesteur"
								value={NomBio}
							//    type="number"
								style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
								onChange={(e) => {
									// if (MonID === 0){
									// 	setTypeBio( e.target.value )
									// }
									
								}}

							/>
					</Col>

						</Row>







				<Row form>


					
					{/* NomMacon */}
					<Col md="2" className="form-group">
					<label htmlFor="NomMacon">Nom Macon*</label>
					<FormInput
						id="NomMacon"
						// placeholder="NomMacon"
						// invalid 
						/* required */
						value={NomMacon}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setNomMacon(e.target.value)
							}	
						}}
					/>
					</Col>

					{/* PrenomMacon */}
					<Col md="2" className="form-group">
					<label htmlFor="PrenoMacon">Prénom Macon*</label>
					<FormInput
						id="PrenomMacon"
						// placeholder="PrenoMacon"
						// invalid 
						/* required */
						value={PrenomMacon}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setPrenomMacon(e.target.value)
							}	
						}}
					/>
					</Col>



						{/* TelMacon1 */}
						<Col md="2" className="form-group">
						<label htmlFor="TelMacon1">TelMaçon1*</label>

						<FormInput
									id="TelMacon1"
									// placeholder="TelClient1"
									// invalid 
									value={TelMacon1}
								//    type="number"
									style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
									onChange={(e) => {
										if (MonID === 0){
											const NB = e.target.value
											if (!isNaN( NB )) {
												setTelMacon1( NB )
											}else {
												alert( 'Saisir un numéro valide SVP')
											}
											
										}
										
									}}
									/*onChange={() => {}} */
								/>

						</Col>

						{/* TelMacon2 */}
						<Col md="2" className="form-group">
						<label htmlFor="TelMacon2">TelMacon2</label>

						<FormInput
									id="TelMacon2"
									// placeholder="TelClient2"
									// invalid 
									value={TelMacon2}
								//    type="number"
									style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
									onChange={(e) => {
										if (MonID === 0){
											const NB = e.target.value
											if (!isNaN( NB )) {
												setTelMacon2( NB )
											}else {
												alert( 'Saisir un numéro valide SVP')
											}
											
										}
										
									}}
									/*onChange={() => {}} */
								/>

						</Col>



						{/* NomControleur */}
						<Col md="2" className="form-group">
						<label htmlFor="NomControleur">Nom Contrôleur</label>

						<FormInput
									id="NomControleur"
									// placeholder="NbFemme"
									// invalid 
									value={NomControleur}
								// type="number"
									style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
									onChange={(e) => {
										if (MonID === 0){
											const NB = e.target.value
											setNomControleur( NB )
											
										}
										
									}}
									/*onChange={() => {}} */
								/>

						</Col>


						{/* PrenomControleur */}
						<Col md="2" className="form-group">
						<label htmlFor="PrenomControleur">Prénom Contrôleur</label>

						<FormInput
									id="PrenomControleur"
									// placeholder="NbFemme"
									// invalid 
									value={PrenomControleur}
								//    type="number"
									style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
									onChange={(e) => {
										if (MonID === 0){
											const NB = e.target.value
											setPrenomControleur( NB )
											
										}
										
									}}
									/*onChange={() => {}} */
								/>

						</Col>



				</Row>




					<Row>

	
				{/* Région */}
					<Col md="3" className="form-group">
					<label htmlFor="Région">Région*</label>
						<FormSelect id="Région"
						/* required */
						value={Region} 
						style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
							onChange={(e) => {
								// alert( e.target.value ) 
								//setRegion(e.target.value)
								let Prov,Reg, Tab = []  
								Prov =  Store2.session("Province") 
								Reg =  Store2.session("Region") 
								const NomRegion = Reg.find(prov => prov.nom == e.target.value );
								Tab =  Store2.session("Localisation") 
								//alert( JSON.stringify( Reg ) )

				const LaListe = Prov.filter(prov => prov.idregion == NomRegion.idregion );
				
		       setListeProvince( LaListe )
			   setRegion( NomRegion.nom )

				// alert(  JSON.stringify( NomRegion.idregion ) )
			 //	ListeProvince = JSON.stringify( TabListe)
							}}
						>
							<option></option>
							{
							ListeRegion.map( item => ( 

							<option  key={item.idregion }  value={item.nom } > { item.nom} </option>
			
							))
						}
						</FormSelect>
					</Col>




                 {/* Province */}
					<Col md="3" className="form-group">
					<label htmlFor="Province">Cercle/Département*</label>
						<FormSelect id="Province"
						/* required */
						value={Province} 
						style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
							onChange={(e) => {
							 //  alert( e.target.value ) 
							//	NomProvince(e.target.value)
								let Com,Prov2, Tab = []
								Com =  Store2.session("Commune") 
								Prov2 =  Store2.session("Province") 
								const LaProvince = Prov2.find(prov => prov.nom == e.target.value );
								Tab =  Store2.session("Localisation") 

				const LaListe = Com.filter(com => com.idprovince == LaProvince.idprovince  );
				
				setListeCommune( LaListe )
				//setProvince( LaProvince.nom )
				setProvince( LaProvince.nom )
				   
				//alert(  JSON.stringify(LaProvince.idprovince)  )
			 //	ListeProvince = JSON.stringify( ListeCommune)


							}}
						>
							<option></option>
							{
							ListeProvince.map( item => ( 
								<option  key={item.idprovince}  value={item.nom }  > { item.nom } </option>
			
							))
						}
						</FormSelect>
					</Col>



                   {/* commune */}
					<Col md="3" className="form-group">
					<label htmlFor="Commune">Commune*</label>
						<FormSelect id="Province"
						/* required */
						value={Commune} 
						style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
							onChange={(e) => {
								setCommune(e.target.value)
							}}
						>
							<option></option>
							{
							ListeCommune.map( item => ( 

								<option  key={item.idcommune  } > { item.nom} </option>
			
							))
						}
						</FormSelect>
					</Col>



					{/* Village */}
					<Col md="3" className="form-group">
					<label htmlFor="Village">Village*</label>
					<FormInput
						id="Village"
						// placeholder="Village"
						// invalid 
						value={Village}
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						onChange={(e) => {
							if (MonID === 0){
								setVillage(e.target.value)
							}
							
						}}
						/*onChange={() => {}} */
					/>
					</Col>
					</Row>



					<Row>

					{/* DateFinTrx */}
					<Col md="3" className="form-group">
					<label htmlFor="DateFinTrx">Date fin travaux</label>
					<FormInput
						id="DateFinTrx"
						//placeholder= { DateFinTrx }
						 
						value={ DateFinTrx }
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						type="date"
						onChange={(e) => {
						// alert(e.target.value)
						setDateFinTrx(e.target.value)
							
						}}
						/*onChange={() => {}} */
					/>

					</Col>
					{/* DateCtrlQte */}
					<Col md="3" className="form-group">
					<label htmlFor="DateCtrlQte">Date contrôle qualité</label>
					<FormInput
						id="DateCtrlQte"
						//placeholder= { DateFinTrx }
						 
						value={ DateCtrlQualite }
                        style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
						type="date"
						onChange={(e) => {
						// alert(e.target.value)
						setDateCtrlQualite(e.target.value)
							
						}}
						/*onChange={() => {}} */
					/>
					</Col>


				{/* PrevisionCnx */}
				<Col md="3" className="form-group">
				<label htmlFor="ConLatrine">Prévision connexion latrine</label>
				
				<FormSelect id="PrevisionCnx"
				/* required */
				value={Conlatrine } 
                style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
				onChange={(e) => {
					setConlatrine(e.target.value)
				}}
				>
				<option></option>
				<option>Oui</option>
				<option>Non</option>

				</FormSelect>


				</Col>

					{/* EtatLarine */}
				<Col md="3" className="form-group">
				<label htmlFor="EtatLat">Etat latrine</label>

				<FormSelect id="EtatLat"
				/* required */
				value={ EtatLatrine } 
                style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
				onChange={(e) => {
					setEtatLatrine(e.target.value)
				}}
				>
				<option></option>
				<option>Construit</option>
				<option>Non Construit</option>

				</FormSelect>

				</Col>



					</Row>



			<Row>

			
			{/* checked */}
			<Col md="12" className="form-group">

				{  TypeConnexion != "Entreprise" ? 
					<Switch
					checked={checked}
					checkedChildren="Active &nbsp;&nbsp;&nbsp;"
					unCheckedChildren=" &nbsp; Desactive "
					// className="kuma-switch-no-text" 
					onChange={() => {
					   //alert(   !Active )
						setActive(  !checked )
						setchecked(  !checked )
						
					}}
      			  />
			
				: null }
				


					</Col>


			{/* Images */}
			<Col md="2" className="form-group">
			<label htmlFor="thematique">  {  MonID === 0 ? null : "Images"  } </label>
			<div>
			{ MonID === 0 ? null : AffichePhoto()   }
			</div>

			</Col>


			</Row>




			<Row form>

			{/* Description */}
			<Col md="12" className="form-group">
			<label htmlFor="Observation">Observation</label>
			<FormTextarea id="Observation" rows="5" 
			value= {Observation}
            style={{  borderColor: '#16D889', borderWidth: 2, borderRadius: 5,   }}
				onChange={(e) => {
					setObservation(e.target.value)
				}}	
			/>



			</Col>
			</Row>





				  <Button  onClick={ () => Enregistrer() } 
                                           marginRight={8} theme="success">
                  Enregistrer
                </Button>

				
                <Button onClick={() =>  window.location.href = '/ListeOuvrages'  } marginRight={8} 
                      outline theme="danger" className="mb-2 mr-1" >
                      Annuler
                      </Button>

				</Form>
			  </Col>
			</Row>
		  </ListGroupItem>
		</ListGroup>
	  </Card>

        )

}



  
export default F_MiseEnService;
