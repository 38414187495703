import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  DatePicker,
  Alert,
} from "shards-react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
// import Store2 from "../../views/Storage/Store2"; 
import Store2 from "store2"; 
import Params from "../Config/Params"; 
import moment from 'moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select'

import { Album, Dialog  } from 'uxcore';
import { Button as MonButton } from 'uxcore';

const { Photo } = Album;


			
const F_MiseEnService = (props) =>{

const Token = Store2.session("token" ) 

const LigneMiseService =  Store2.session("ligneSelectMiseService")
 // alert( JSON.stringify(LigneMiseService) )
////  alert( JSON.stringify ( LigneMiseService.Province ))
const [IdOuvrage,setIdOuvrage] = useState(0)

const [MonID,setMonID] = useState(  LigneMiseService ? LigneMiseService.idouvrage : 0)
const [Region,setRegion] = useState(  LigneMiseService ? LigneMiseService.Region : '')
const [Province,setProvince] = useState(  LigneMiseService ? LigneMiseService.Province : '')
const [Commune,setCommune] = useState(  LigneMiseService ? LigneMiseService.Commune : '')
const [Village,setVillage] = useState(  LigneMiseService ? LigneMiseService.Village : '')
const [Observation,setObservation] = useState(  LigneMiseService ? LigneMiseService.observation : '')

const [PlanCode,setPlanCode] = useState(  LigneMiseService ? LigneMiseService.plancode : '')
const [TypeOuvrage,setTypeOuvrage] = useState(  LigneMiseService ? LigneMiseService.typeouvrage : '')
const [NomPrenClient,setNomPrenClient] = useState(  LigneMiseService ? LigneMiseService.NomPrenomClient : '')
const [NomPrenMacon,setNomPrenMacon] = useState(  LigneMiseService ? LigneMiseService.NomPrenomMacon : '')
const [NomEcb,setNomEcb] = useState(  LigneMiseService ? LigneMiseService.NomEcb : '')


const [EtatOuvrage,setEtatOuvrage] = useState( LigneMiseService ? LigneMiseService.etatouvrage : '')
const [ObsEtat,setObsEtat] = useState( LigneMiseService ? LigneMiseService.observation_etat : '')
const [Formation,setFormation] = useState( LigneMiseService ? LigneMiseService.formationclient : '')
const [Thematique,setThematique] = useState(  LigneMiseService ? LigneMiseService.thematiqueformation :  '')


const [DateMiseService,setDateMiseService] = useState( LigneMiseService ?  new Date (LigneMiseService.datemieenservice)   : new Date () )
const [DateSuivi,setDateSuivi] = useState( LigneMiseService ?  new Date (LigneMiseService.datemieenservice)   : new Date () )
const [DateRdv,setDateRdv] = useState( LigneMiseService ?  new Date (LigneMiseService.daterdv)   : new Date () )


const ModificationData = async (Donnees) => {


	axios.post( Params.Api + '/api/dsh_miseService_modif/?token=' + Token , Donnees )
		
  
	.then(function (response) {
		
	window.location.href = '/Mise_en_service' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }

  
const InsertionData = async (Donnees) => {


	axios.post( Params.Api + '/api/postmiseenservice/?token=' + Token , Donnees )
		
  
	.then(function (response) {

	window.location.href = '/Mise_en_service' 

	})
	.catch(function (error) {
	  if (error.response) {

		  console.log(error.response.data);
		  console.log(error.response.status);
		  console.log(error.response.headers);


	  }  else if (error.request){

		  console.log(error.request);

	  }else {
		  // Something happened in setting up the request that triggered an Error
		  console.log('Error', error.message);
		}

	  alert(`Erreur API : constacter l'administrateur \n ` + error.message );
	});


  }



const Enregistrer = () => {
  //this.props.history.push("/Accueil")


  if( MonID === 0 ){
// alert ('Nouveau')
//  moment(DateRdv).format("YYYY-MM-DD HH:mm:ss"),


	var Donnees = {
		IdOuvrage  : IdOuvrage,
		// Idmiseenservice  : LigneMiseService.idmiseenservice,
		Etat  : EtatOuvrage , 
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		Theme  : Thematique , 
		DateRDv  : moment(DateRdv).format("YYYY-MM-DD"),
		DateSuivi  :  moment(DateSuivi).format("YYYY-MM-DD"),
		Formation  :Formation, 
		DateMiseService  :  moment(DateMiseService).format("YYYY-MM-DD"),
	 
	  };

	InsertionData( Donnees )
	

  }else{
	// alert ('Modification')
	var Donnees = {
		IdOuvrage  : LigneMiseService.idouvrage,
		Idmiseenservice  : LigneMiseService.idmiseenservice,
		Etat  : EtatOuvrage , 
		ObservationEtat  : ObsEtat, 
		Observation  :Observation, 
		Theme  : Thematique , 
		DateRDv  : moment(DateRdv).format("YYYY-MM-DD"),
		DateSuivi  : moment(DateSuivi).format("YYYY-MM-DD"),
		Formation  :Formation, 
		DateMiseService  : moment(DateMiseService).format("YYYY-MM-DD"),
	 
	  };
	  
	 ModificationData( Donnees )
	//  OkInsertion()
  }


 // alert( JSON.stringify(Donnees) )
  //alert( "Mise à jour terminée avec succès")

}


const ChangeDateMiseService = (value) => {
	setDateMiseService( new Date(value) )
}

const ChangeDateRDV = (value) => {
	setDateRdv( new Date(value) )
}

const ChangeDateSuivi = (value) => {
	setDateSuivi( new Date(value) )
}

// ----------------------------------------

  
 const  handleClick = () => {
	//Album.showButton(true)
    Album.show({
      photos: [
        <Photo
          src={ require('../Logo/logo.jpg')}
          key={0}
        />,
        <Photo 
		src={ require('../Logo/Logo_PnbBf.jpg')}  key={1} />,

        
      ],
    });
  }

// () => <Redirect to="/GARBALw" />

 const  OkInsertion = () => {
	Dialog.info({
	  title: 'BioSynchro',
	  content: 'Insertion Terminé',
	  onOk() {  },
	});
  }
// -------------------------------------------------

const AffichageValeur = (MaListe, Valeur) => {
	//alert( Valeur)
	//alert( MaListe[1].plancode)
	// var Donnees =  MaListe.filter( (item) => {
	// 	return item.plancode == Valeur;
		
	// });

	let Donnees = MaListe.filter(item => item.plancode === Valeur);

	//alert( JSON.stringify(Donnees) )
	setTypeOuvrage(   Donnees[0].typeouvrage  )
	setNomPrenClient(  Donnees[0].NomPrenomClient )
	setNomPrenMacon(  Donnees[0].NomPrenomMacon )
	setNomEcb(  Donnees[0].NomEcb )

	setRegion(  Donnees[0].Region )
	setProvince(  Donnees[0].Province )
	setCommune(  Donnees[0].Commune )
	setVillage(  Donnees[0].Village )
	setIdOuvrage( Donnees[0].idouvrage )

}




  const options = Store2.session("ouvrageinfo" )
  const ouvrageinfoall = Store2.session("ouvrageinfoall" )
  
  //alert(JSON.stringify( ouvrageinfoall[1].plancode) )
// const options = [
// 	{ value: 'chocolate', label: 'Chocolate' },
// 	{ value: 'strawberry', label: 'Strawberry' },
// 	{ value: 'vanilla', label: 'Vanilla' }
//   ]

  const PlacodeNew = () => (
	<Select
	 options={options}
	 defaultValue={PlanCode}
	// onChange={setSelectedOption}
	 onChange={(x) => {

		//setPlanCode(x.value)
		AffichageValeur( ouvrageinfoall , x.label)
		
		}}


	  />
  )
  
  const PlacodeModif = () =>{
	  return (

		<FormInput
		id="plancode"
		placeholder="plan code"
		invalid  
		/* required */
		value={PlanCode} 
		onChange={(e) => {

		//		setPlanCode(e.target.value)

		}}
	  />

  )}
  
  

//-----------------------------


const  title = "Détails de saisie"

  return (
	  <Card small className="mb-4" >
	  

            { /**
             <Alert theme="success">
                    aaaaaaaaaaaa
                </Alert>

             */}
		
		<ListGroup flush>
		
		  <ListGroupItem className="p-3">
			<Row>
			  <Col>
				<Form  // onSubmit={this.onEnvoyer}
				>
				  <Row form>
					{/* plancode */}
					<Col md="6" className="form-group">
					  <label htmlFor="plancode" >Plan code</label>
	
	  { MonID === 0 ? PlacodeNew() : PlacodeModif()}

					</Col>
					{/* typeouvrage */}
					<Col md="6" className="form-group">
					  <label htmlFor="typeouvrage">Type ouvrage</label>
					  <FormInput
						id="typeouvrage"
						placeholder="typeouvrage"
						invalid 
						value={TypeOuvrage} 
						onChange={(e) => {
							// if (MonID === 0){
							// 	setTypeOuvrage(e.target.value)
							// }
							
						}}
					  />
					</Col>
				  </Row>

				  <Row form>
					{/* NomPrenomClient */}
					<Col md="4" className="form-group">
					  <label htmlFor="NomPrenomClient">Nom Prenom Client</label>
					  <FormInput
						id="NomPrenomClient"
						placeholder="NomPrenomClient"
						invalid 
						value= {NomPrenClient}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomPrenClient(e.target.value)
							// }

						}}
						
					  />
					</Col>

					{/* NomPrenomMacone */}
					<Col md="4" className="form-group">
					  <label htmlFor="NomPrenomMacon">Nom Prenom Macon</label>
					  <FormInput
						id="NomPrenomMacon"
						placeholder="NomPrenomMacon"
						invalid 
						/* required */
						value={NomPrenMacon}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomPrenMacon(e.target.value)
							// }	
						}}
					  />
					</Col>

					{/* NomEcb */}
					<Col md="4" className="form-group">
					<label htmlFor="NomEcb">Nom Ecb</label>
					  <FormInput
						id="NomEcb"
						placeholder="NomEcb"
						invalid 
						/* required */
						value={NomEcb}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setNomEcb(e.target.value)
							// }
							
						}}
					  />
					</Col>
				  </Row>

				  <Row>

					{/* Region */}
					<Col md="3" className="form-group">
					<label htmlFor="Region">Region</label>
					  <FormInput
						id="Region"
						placeholder="Region"
						invalid 
						/* required */
						value={Region}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setRegion(e.target.value)
							// }
							
						}}
					  />
					</Col>
					{/* Province */}
					<Col md="3" className="form-group">
					  <label htmlFor="Province">Province</label>
					  <FormInput
						id="Province"
						placeholder="Province"
						invalid 
						value={Province}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setProvince(e.target.value)
							// }
							
						}}
						/*onChange={() => {}} */
					  />
					</Col>

					{/* commune */}
					<Col md="3" className="form-group">
					<label htmlFor="commune">Commune</label>
					  <FormInput
						id="commune"
						placeholder="commune"
						invalid 
						/* required */
						value={Commune}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setCommune(e.target.value)
							// }
							
						}}
					  />
					</Col>
					
					{/* Village */}
					<Col md="3" className="form-group">
					  <label htmlFor="Village">Village</label>
					  <FormInput
						id="Village"
						placeholder="Village"
						invalid 
						value={Village}
						onChange={(e) => {
							// if (MonID === 0){
							// 	setVillage(e.target.value)
							// }
							
						}}
						/*onChange={() => {}} */
					  />
					</Col>
				  </Row>
 
				  <Row form>
					{/* DateMiseEnService */}
					<Col md="2" className="form-group">
					  <label htmlFor="DateMiseEnService">Date de mise en service</label>
					<DatePicker
					 //  placeholderText="-"
					 // className="form-control"
					 //  showDisabledMonthNavigation
					 dateFormat="dd-MM-yyyy hh:mm:ss"
					selected={DateMiseService}  
					 selectsStart  
					 startDate={ new Date() }
					 // endDate={this.state.endDate}
					 onChange={date => ChangeDateMiseService(date)}
					 // autosuggest="off"
					 dropdownMode="select"

					/>

					</Col>
					{/* DateSuivi */}
					<Col md="2" className="form-group">
					  <label htmlFor="DateSuivi">Date de suivi</label>
					  <DatePicker
					 //  placeholderText="-"
					 // className="form-control"
					 //  showDisabledMonthNavigation
					 dateFormat="dd-MM-yyyy hh:mm:ss"
					selected={DateSuivi}  
					 selectsStart  
					 startDate={ new Date() }
					 // endDate={this.state.endDate}
					 onChange={date => ChangeDateSuivi(date)}
					 // autosuggest="off"
					 dropdownMode="select"

					/>
						
					</Col>
				  {/* DateRDV */}
					<Col md="2" className="form-group">
					  <label htmlFor="DateRDV">Date du prochain RDV</label>
					  <DatePicker
					 //  placeholderText="-"
					 // className="form-control"
					 //  showDisabledMonthNavigation
					 dateFormat="dd-MM-yyyy hh:mm:ss"
					selected={DateRdv}  
					 selectsStart  
					  startDate={ new Date() }
					 // endDate={this.state.endDate}
					 onChange={date => ChangeDateRDV(date)}
					 // autosuggest="off"
					 dropdownMode="select"

					/>
						
					</Col>

				 {/* EtatOuvrage */}
					<Col md="2" className="form-group">
					<label htmlFor="EtatOuvrage">Etat ouvrage</label>
					  <FormSelect id="EtatOuvrage"
					  /* required */
					  value={EtatOuvrage} 
						onChange={(e) => {
							setEtatOuvrage(e.target.value)
						}}
					  >
						<option></option>
						<option>Fonctionnel</option>
						<option>Non fonctionnel</option>
						<option>Nouveau chargement</option>
	
					  </FormSelect>
					 </Col>

					{/* observation_etat */}
					  <Col md="2" className="form-group">
					<label htmlFor="observation_etat">Observation état</label>
					  <FormSelect id="observation_etat"
					  /* required */
					  value={ObsEtat} 
						onChange={(e) => {
							setObsEtat(e.target.value)
						}}
					  >
						<option>Auccun</option>
						<option>Abandon</option>
						<option>Foyer non utilisé</option>
						<option>Foyer non utilisé</option>
						<option>Lampe et/ou foyé usagé</option>
						<option>Lampe non utilisée</option>
						<option>Mauvaise qualite de bouse</option>
						<option>Non chargé</option>
						<option>Non mis en service</option>
						<option>Non respect du chargement quotidien</option>
						<option>Pas d'accessoires installés</option>
						<option>Pas de production de compost</option>
						<option>Perte d'animaux</option>
						<option>Problème technique (fuite de réseau ou fuite dôme)</option>
						<option>Décès</option>
						<option>Arrêt de chargement</option>

	
						

					  </FormSelect>
					  </Col>

				  </Row>


				  <Row form>

					{/* Formation */}
					<Col md="4" className="form-group">
					<label htmlFor="formation">Avez-vous reçu une formation* ?</label>
					  <FormSelect id="formation"
					  /* required */
					  value={Formation} 
						onChange={(e) => {
							setFormation(e.target.value)
						}}
					  >
						<option></option>
						<option>Oui</option>
						<option>Non</option>

					  </FormSelect>


					</Col>

					{/* thematique */}
					<Col md="4" className="form-group">
					<label htmlFor="thematique">Thématique de formation</label>
					  <FormSelect id="thematique"
					  /* required */
					  value={Thematique} 
						onChange={(e) => {
							setThematique(e.target.value)
						}}
					  >
						 <option>Auccun</option>
						<option>Production de compost</option>
						<option>Production d’aliment pour volaille</option>
						<option>Production d’aliment pour porc</option>
						<option>Production d’asticot</option>
						<option>Production de pesticide à base d’effluent</option>
						<option>Autre (à préciser dans une zone  de saisie)</option>

					  </FormSelect>


					</Col>


	{/* Images */}
	<Col md="4" className="form-group">
					<label htmlFor="thematique">Images</label>
					  <div>
					<MonButton  onClick={() => handleClick() } >Afficher </MonButton>
				</div>


					</Col>


					</Row>

				  <Row form>
				  
					{/* Observation */}
					<Col md="12" className="form-group">
					  <label htmlFor="Observation">Observation</label>
					  <FormTextarea id="Observation" rows="5" 
					  value= {Observation}
						onChange={(e) => {
							setObservation(e.target.value)
						}}	
					  />

		
		
					</Col>
				  </Row>



				  <Button  onClick={ () => Enregistrer() } 
                                           marginRight={8} theme="success">
                  Enregistrer
                </Button>

				</Form>
			  </Col>
			</Row>
		  </ListGroupItem>
		</ListGroup>
	  </Card>

        )

}



  
export default F_MiseEnService;
